import { Fetcher, useQuery } from 'react-suspended-query';
import { IServiceCallback } from '@alcs/gwt';

import { useQueryContext } from './QueryContext';

type RemoveFirstFromTuple<T extends unknown[]> = ((...args: T) => void) extends (
    firstArg: unknown,
    ...other: infer I
) => void
    ? I
    : [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createQuery = <T extends (callback: IServiceCallback, ...args: any[]) => any>(key: string, fetcher: T) => {
    const useFetch = (...args: RemoveFirstFromTuple<Parameters<T>>): Awaited<ReturnType<T>> => {
        const { FormCache, callback } = useQueryContext();

        const data = useQuery(
            [key, ...args],
            ((_key: string, ...args: RemoveFirstFromTuple<Parameters<T>>) => fetcher(callback, ...args)) as Fetcher<
                unknown,
                [string, ...RemoveFirstFromTuple<Parameters<T>>]
            >,
            FormCache,
        );

        return data as Awaited<ReturnType<T>>;
    };

    return useFetch;
};
