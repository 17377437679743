import React, { useCallback, useState } from 'react';
import { commonsDefaultI18n, useI18n } from '@alcs/i18n';
import { MenuItem, styled, TextFieldProps, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';

import { FieldBaseProps, StyledTextField, useFieldBase } from './Fields';
import { SelectFieldIcon, SelectFieldIconContext } from './SelectFieldIcon';

const NonEditableFieldWrapper = styled('div', {
    label: 'NonEditableFieldWrapper',
    shouldForwardProp: propName => propName !== 'width',
})<{ width?: number }>(({ width }) => ({
    width,
}));

export type SelectFieldProps<T> = FieldBaseProps<T> & {
    items: T[];
    renderItem: (item: T) => React.ReactNode;
    renderValue?: (item: T) => React.ReactNode;
    getValueKey?: (value: T) => number | string;
    displayEmpty?: boolean;
    required?: boolean;
};

const stopPropagationHandler = (e: React.MouseEvent<HTMLLIElement>) => e.stopPropagation();

function addEmpty(menuItems: JSX.Element[], add: boolean) {
    add &&
        menuItems.unshift(
            <MenuItem onClick={stopPropagationHandler} key={-1} value={-1}>
                &nbsp;
            </MenuItem>,
        );
    return menuItems;
}

export const SelectField = <T,>({
    items,
    displayEmpty = false,
    renderItem,
    renderValue = renderItem,
    required = false,
    validate: propsValidator,
    getValueKey: getValueKeyFn,
    onChange,
    ...other
}: SelectFieldProps<T>) => {
    const i18n = useI18n(commonsDefaultI18n);
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const toggle = useCallback(() => {
        setOpen(old => !old);
    }, []);

    const defaultGetValueKey = useCallback((value: T) => items.findIndex(item => isEqual(item, value)), [items]);

    const getValueKey = getValueKeyFn ?? defaultGetValueKey;

    const validator = useCallback(
        async (valueToValidate: T) => {
            if (required && items.findIndex(value => getValueKey(value) === getValueKey(valueToValidate)) === -1) {
                return i18n.required;
            }

            return propsValidator?.(valueToValidate);
        },
        [required, items, propsValidator, getValueKey, i18n.required],
    );

    const { editable, disabled, value, textFieldProps, setFieldValue, width, error, touched } = useFieldBase({
        ...other,
        validate: validator,
    });
    const displayError = Boolean(error && touched);

    const handleChange = useCallback(
        (e: React.ChangeEvent<{ value: number | string }>) => {
            setFieldValue(items.find(value => getValueKey(value) === e.target.value)!);
            onChange?.(e as React.ChangeEvent<{ value: string }>);
        },
        [getValueKey, items, onChange, setFieldValue],
    );

    if (!editable) {
        return (
            <NonEditableFieldWrapper width={width} style={{ ...textFieldProps?.style }}>
                <Typography component="div">{renderValue(value)}</Typography>
            </NonEditableFieldWrapper>
        );
    }

    return (
        <SelectFieldIconContext.Provider
            value={{
                displayError,
                error,
                onClick: toggle,
            }}
        >
            <StyledTextField
                {...(textFieldProps as Omit<TextFieldProps, 'error'>)}
                onBlur={undefined}
                disabled={disabled}
                onChange={handleChange as unknown as React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>}
                select
                value={getValueKey(value)}
                SelectProps={{
                    displayEmpty,
                    onOpen: handleOpen,
                    onClose: handleClose,
                    open,
                    IconComponent: SelectFieldIcon,
                    ...textFieldProps?.SelectProps,
                    MenuProps: {
                        container: global.document?.getElementById('reactPopovers'),
                        ...textFieldProps?.SelectProps?.MenuProps,
                        disableScrollLock: true,
                        marginThreshold: 0,
                    },
                }}
                InputProps={{
                    endAdornment: undefined,
                    ...textFieldProps?.InputProps,
                }}
                style={{ width, ...textFieldProps?.style }}
            >
                {addEmpty(
                    items.map(item => {
                        const key = getValueKey(item);

                        return (
                            <MenuItem key={key} value={key} onClick={stopPropagationHandler}>
                                {renderItem(item)}
                            </MenuItem>
                        );
                    }),
                    displayEmpty,
                )}
            </StyledTextField>
        </SelectFieldIconContext.Provider>
    );
};
