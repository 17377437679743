import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum UserErasureStatus {
    ACT = 'ACT',
    DONE = 'DONE',
    CAN = 'CAN',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof UserErasureStatus>>('enums.UserErasureStatus', {
    ACT: 'Active',
    DONE: 'Done',
    CAN: 'Canceled',
});

export const UserErasureStatusBundle: EnumBundle<typeof UserErasureStatus> = {
    value: UserErasureStatus,
    defaultI18n,
};
