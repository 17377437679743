import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum PeriodicityType {
    DAY = 'DAY',
    WORKDAY = 'WORKDAY',
    KM_LOAD = 'KM_LOAD',
    KM_UNLOAD = 'KM_UNLOAD',
}

const periodicityTypeI18n = createDefaultI18n<EnumI18n<typeof PeriodicityType>>('enums.PeriodicityType', {
    DAY: 'Every day at',
    WORKDAY: 'Every working day at',
    KM_LOAD: 'KM till loading',
    KM_UNLOAD: 'KM till unloading',
});

export const PeriodicityTypeBundle: EnumBundle<typeof PeriodicityType> = {
    value: PeriodicityType,
    defaultI18n: periodicityTypeI18n,
    icons: false,
};
