import React from 'react';
import { Tooltip } from '@mui/material';
import type { StaticImageData } from 'next/image';

export type ISOImageProps = {
    code: number;
    previewSource: StaticImageData;
    source: StaticImageData;
};

export const ISOImage = ({ code, previewSource, source }: ISOImageProps) => (
    <Tooltip title={<img src={source.src} width={source.width} height={source.height} alt={`ISO ${code}`} />}>
        <img src={previewSource.src} width={previewSource.width} height={previewSource.height} alt={`ISO ${code}`} />
    </Tooltip>
);
