import { createPopupGroup, PopupGroup } from 'reactive-popups';

const TopLeftSnackbarGroup = createPopupGroup();
const BottomLeftSnackbarGroup = createPopupGroup();

export type SnackbarContainerPosition = 'top-left' | 'bottom-left';

export const SnackbarGroup: Record<SnackbarContainerPosition, PopupGroup> = {
    'bottom-left': BottomLeftSnackbarGroup,
    'top-left': TopLeftSnackbarGroup,
};
