import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum Optimization {
    NONE = '0',
    FAST = '1',
    ACCURATE = '2',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof Optimization>>('enums.RoutingOptimization', {
    NONE: 'None',
    FAST: 'Fast',
    ACCURATE: 'Accurate',
});

export const RoutingOptimizationBundle: EnumBundle<typeof Optimization> = {
    value: Optimization,
    defaultI18n,
};
