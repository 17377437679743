import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export const findFreeVinStatuColors: Record<FindFreeVinStatus, string> = {
    DELAY: '#FF0000',
    LESS_THAN_TWO_DAYS: '#FF9900',
    TWO_DAYS_AND_MORE: '#33CC00',
    MULTIPLE: '#2D79B2',
};

export enum FindFreeVinStatus {
    DELAY = 'DELAY',
    LESS_THAN_TWO_DAYS = 'LESS_THAN_TWO_DAYS',
    TWO_DAYS_AND_MORE = 'TWO_DAYS_AND_MORE',
    MULTIPLE = 'MULTIPLE',
}

const statusIcons: EnumIcons<typeof FindFreeVinStatus> = {
    DELAY: {
        path: `MapIcons/PLACE.svg`,
        color: findFreeVinStatuColors[FindFreeVinStatus.DELAY],
    },
    LESS_THAN_TWO_DAYS: {
        path: `MapIcons/PLACE.svg`,
        color: findFreeVinStatuColors[FindFreeVinStatus.LESS_THAN_TWO_DAYS],
    },
    TWO_DAYS_AND_MORE: {
        path: `MapIcons/PLACE.svg`,
        color: findFreeVinStatuColors[FindFreeVinStatus.TWO_DAYS_AND_MORE],
    },
    MULTIPLE: {
        path: `MapIcons/MULTIPLE_PLACE.svg`,
        color: findFreeVinStatuColors[FindFreeVinStatus.MULTIPLE],
    },
};

const markerI18n = createDefaultI18n<EnumI18n<typeof FindFreeVinStatus>>('enums.FindFreeVinStatusBundle', {
    DELAY: 'Delay!',
    LESS_THAN_TWO_DAYS: '< 2 days',
    TWO_DAYS_AND_MORE: '2+ days',
    MULTIPLE: 'Multiple Vins in location',
});

export const FindFreeVinStatusBundle: EnumBundle<typeof FindFreeVinStatus> = {
    value: FindFreeVinStatus,
    defaultI18n: markerI18n,
    icons: statusIcons,
};
