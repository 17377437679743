import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum RentContractsStatus {
    ALL = 'ALL',
    ACT = 'ACT',
    CAN = 'CAN',
    END = 'END',
}

const RentContractsStatusI18n = createDefaultI18n<EnumI18n<typeof RentContractsStatus>>('enums.RentContractsStatus', {
    ALL: 'All',
    ACT: 'Active',
    CAN: 'Canceled',
    END: 'Ended',
});

export const RentContractsStatusBundle: EnumBundle<typeof RentContractsStatus> = {
    value: RentContractsStatus,
    defaultI18n: RentContractsStatusI18n,
    icons: true,
};
