import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum AuthorizedRoles {
    DIR = 'DIR',
    MGR = 'MGR',
}

const authorizedRolesI18n = createDefaultI18n<EnumI18n<typeof AuthorizedRoles>>('enums.AuthorizedRoles', {
    DIR: 'Director',
    MGR: 'General manager',
});

export const AuthorizedRolesBundle: EnumBundle<typeof AuthorizedRoles> = {
    value: AuthorizedRoles,
    defaultI18n: authorizedRolesI18n,
    icons: false,
};
