import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum MailVisibility {
    D = 'D',
    N = 'N',
    Y = 'Y',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof MailVisibility>>('enums.Visbility', {
    D: 'Default',
    N: 'Do not receive',
    Y: 'Receive',
});

export const MailVisibilityBundle: EnumBundle<typeof MailVisibility> = {
    value: MailVisibility,
    defaultI18n,
};
