import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';
import { createEnumValueValidator } from '../utils/createEnumValueValidator';

const defaultI18n = createDefaultI18n<EnumI18n<typeof PriceType>>('enums.PriceType', {
    TRQ: 'Transportation',
    DAY: 'Day',
    CRG: 'Cargo',
    VEH: 'Vehicle',
    KM: 'Km',
});

export enum PriceType {
    TRQ = 'TRQ',
    DAY = 'DAY',
    CRG = 'CRG',
    VEH = 'VEH',
    KM = 'KM',
}

export const PriceTypeBundle: EnumBundle<typeof PriceType> = {
    value: PriceType,
    defaultI18n,
};

export const validatePriceType = createEnumValueValidator(PriceType);
