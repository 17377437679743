import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum TransportationsListStatus {
    LATE = 'LATE',
    ORD = 'ORD',
    LOAD = 'LOAD',
    DLV = 'DLV',
    SCH = 'SCH',
    UNSCH = 'UNSCH',
    CAN = 'CAN',
    ERR = 'PROBL',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof TransportationsListStatus>>('enums.TransportationsListStatus', {
    CAN: 'Canceled',
    DLV: 'Delivered',
    LATE: 'Late pickup',
    LOAD: 'Loaded',
    ORD: 'Ordered',
    ERR: 'Problems',
    SCH: 'Assigned to truck',
    UNSCH: 'Not assigned to truck',
});

export const TransportationsListStatusBundle: EnumBundle<typeof TransportationsListStatus> = {
    defaultI18n,
    value: TransportationsListStatus,
    icons: true,
};
