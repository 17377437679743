import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum PaymentMethods {
    BTRAN = 'BTRAN',
    CARD = 'CARD',
}

const paymentMethodsI18n = createDefaultI18n<EnumI18n<typeof PaymentMethods>>('enums.PaymentMethods', {
    BTRAN: 'Bank transfer',
    CARD: 'Credit card',
});

export const PaymentMethodsBundle: EnumBundle<typeof PaymentMethods> = {
    value: PaymentMethods,
    defaultI18n: paymentMethodsI18n,
    icons: false,
};
