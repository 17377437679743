import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum ContactVisibility {
    PAR = 'PAR',
    PUB = 'PUB',
    PRI = 'PRI',
    GRP = 'GRP',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof ContactVisibility>>('enums.Visbility', {
    PAR: 'Counterparty',
    PUB: 'Visible to all',
    PRI: 'Visible to my company',
    GRP: 'Visible to my group',
});

export const ContactVisibilityBundle: EnumBundle<typeof ContactVisibility> = {
    value: ContactVisibility,
    defaultI18n,
};
