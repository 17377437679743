import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle } from './EnumTypes';

export enum FleetPriceType {
    KM = 'KM',
    DAY = 'DAY',
}

const priceTypeDefaultI18n = createDefaultI18n('enums.PriceType', {
    KM: 'Km',
    DAY: 'Day',
});

export const FleetPriceTypeBundle: EnumBundle<typeof FleetPriceType> = {
    defaultI18n: priceTypeDefaultI18n,
    value: FleetPriceType,
};
