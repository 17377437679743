import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum IncidentType {
    ACCIDENT = 'ACD',
    PROBLEM_UNLOADING = 'PBU',
    TECHNICAL_PROBLEM = 'TCH',
    PROBLEM_LOADING = 'PBL',
}

const IncidentTypeI18n = createDefaultI18n<EnumI18n<typeof IncidentType>>('enums.IncidentType', {
    ACCIDENT: 'Accident',
    PROBLEM_UNLOADING: 'Unloading problem',
    TECHNICAL_PROBLEM: 'Technical problem',
    PROBLEM_LOADING: 'Loading problem',
});

export const IncidentTypeBundle: EnumBundle<typeof IncidentType> = {
    value: IncidentType,
    defaultI18n: IncidentTypeI18n,
};
