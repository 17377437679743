import React, { useEffect } from 'react';
import { Alert, AlertProps, styled } from '@mui/material';

import { OutAnimation } from './OutAnimation';
import { useOutAnimation } from '../utils/useOutAnimation';

const DEFAULT_AUTOHIDE_DURATION = 4000;

export type SnackbarPopupProps = {
    message: React.ReactNode;
    autoHideDuration?: number;
} & AlertProps;

const SnackbarRoot = styled(OutAnimation, {
    label: 'SnackbarRoot',
})({
    pointerEvents: 'all',
    minWidth: 288,
    width: 'fit-content',
});

const SnackbarContent = styled(Alert, {
    label: 'SnackbarContent',
})({
    margin: '5px 0',
});

export const SnackbarPopup: React.FunctionComponent<SnackbarPopupProps> = ({
    message,
    autoHideDuration = DEFAULT_AUTOHIDE_DURATION,
    ...alertProps
}: SnackbarPopupProps) => {
    const { handleClose, ...other } = useOutAnimation();

    useEffect(() => {
        setTimeout(handleClose, autoHideDuration);
    }, [autoHideDuration, handleClose]);

    return (
        <SnackbarRoot {...other}>
            <SnackbarContent {...alertProps} onClose={handleClose}>
                {message}
            </SnackbarContent>
        </SnackbarRoot>
    );
};
