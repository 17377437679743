import React from 'react';
import { GwtContext } from '@alcs/core/components/GwtContext';
import { theme } from '@alcs/core/components/MaterialTheme';
import Popupper from '@alcs/core/components/popups/Popupper';
import { IGwtContext } from '@alcs/gwt';
import { PopupsContextProvider, SnackbarGroups } from '@alcs/popups';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createPluginArray, FormPlugins } from '@reactive-forms/core';
import { domPlugin } from '@reactive-forms/dom';
import { AppProps } from 'next/app';
import Head from 'next/head';

import { ErrorBoundary } from '../components/ErrorBoundary';
import { FatalErrorFallback } from '../components/FatalErrorFallback';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { I18nResourceLoader } from '../components/I18nResourceLoader';
import { createEmotionCache } from '../createEmotionCache';
import authBg from '../images/auth_bg.jpg';

import '../styles/globals.g.scss';

const pluginArray = createPluginArray(domPlugin);

const newTheme = createTheme({
    ...theme,
    typography: {
        fontSize: 14,
    },
});

const AuthWrapper = styled('div', {
    label: 'AuthWrapper',
})({
    minWidth: '100%',
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    backgroundColor: '#fff',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${authBg.src})`,
});

const HeaderWrapper = styled('header', {
    label: 'HeaderWrapper',
})({
    minHeight: '70px',
    background: '#fff',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
});

const MainWrapper = styled('main', {
    label: 'MainWrapper',
})({
    margin: '20px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '110px',
});

const FooterWrapper = styled('footer', {
    label: 'FooterWrapper',
})({
    minHeight: '70px',
    background: '#fff',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.05)',
    padding: '0 10px',
});

type MainAppProps = AppProps & {
    emotionCache?: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

const MainApp = ({ Component, pageProps, emotionCache = clientSideEmotionCache }: MainAppProps) => (
    <ErrorBoundary fallback={<FatalErrorFallback />}>
        <CacheProvider value={emotionCache}>
            <FormPlugins plugins={pluginArray}>
                <I18nResourceLoader>
                    <GwtContext.Provider
                        value={
                            {
                                notifyCanShow: () => {
                                    /** do nothing */
                                },
                            } as unknown as IGwtContext
                        }
                    >
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={newTheme}>
                                <PopupsContextProvider>
                                    <SnackbarGroups />
                                    <Popupper />
                                    <Head>
                                        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                                    </Head>
                                    <AuthWrapper>
                                        <HeaderWrapper>
                                            <Header />
                                        </HeaderWrapper>
                                        <MainWrapper>
                                            <Component {...pageProps} />
                                        </MainWrapper>
                                        <FooterWrapper>
                                            <Footer />
                                        </FooterWrapper>
                                    </AuthWrapper>
                                </PopupsContextProvider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </GwtContext.Provider>
                </I18nResourceLoader>
            </FormPlugins>
        </CacheProvider>
    </ErrorBoundary>
);

export default MainApp;
