import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum FileVisibility {
    PUB = 'PUB',
    GRP = 'GRP',
    PRI = 'PRI',
}

const fileVisibilityI18n = createDefaultI18n<EnumI18n<typeof FileVisibility>>('enums.FileVisibility', {
    PRI: 'My company',
    GRP: 'My company group',
    PUB: 'Everyone who can view the :p0',
});

export const fileVisibilityBundle: EnumBundle<typeof FileVisibility> = {
    value: FileVisibility,
    defaultI18n: fileVisibilityI18n,
};
