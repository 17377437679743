import { useContext } from 'react';
import { CacheGroup, GlobalCache, useQuery } from '@alcs/query';

import { LanguageContext } from './LanguageContext';
import { loadI18n } from './loadI18n';

/**
 * Helper hook, that loads i18n module. Uses Suspense API.
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 *   Warning: do not modify, export, or reuse this function. If any modifications required, please notify the author.
 */
export const useI18nLoader = (moduleName: string, CacheGroup: CacheGroup = GlobalCache) => {
    const language = useContext(LanguageContext);
    return useQuery([moduleName, language, 'loadI18n'], loadI18n, CacheGroup);
};
