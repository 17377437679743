import { createDefaultI18n } from '@alcs/i18n';

export enum EmailConfirmType {
    UAPL = 'UAPL',
    UEM = 'UEM',
}

type EmailConfirmTypeI18n = { [K in keyof typeof EmailConfirmType]: { title: string; message: string } };

export const defaultEmailConfirmTypeI18n = createDefaultI18n<EmailConfirmTypeI18n>('enums.EmailConfirmType', {
    UAPL: {
        title: 'Email address confirmed',
        message:
            "Your email address was successfully confirmed. Your user registration has been sent to company's responsible person to review.",
    },
    UEM: { title: 'Email address verified', message: 'Your email address was successfully verified.' },
});
