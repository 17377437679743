import React from 'react';
import { styled } from '@mui/material';

interface SkeletonProps {
    multiline?: boolean;
}

const SkeletonRow = styled('span', {
    label: 'SkeletonRow',
    shouldForwardProp: propName => propName !== 'variant',
})<{ variant: 'long' | 'short' }>(({ variant }) => ({
    background: 'rgba(0, 0, 0, 0.11)',
    height: '10px',
    borderRadius: '4px',
    display: 'inline-block',
    minWidth: '25px',
    ...(variant === 'long' && {
        width: '100%',
    }),
    ...(variant === 'short' && {
        width: '80%',
        marginBottom: '5px',
    }),
}));

const Skeleton = ({ multiline }: SkeletonProps) => (
    <React.Fragment>
        <SkeletonRow variant="long" />
        {multiline && <SkeletonRow variant="short" />}
    </React.Fragment>
);

export default Skeleton;
