import React, { ReactElement } from 'react';
import { Collapse, Slide } from '@mui/material';

import { OutAnimationBag } from '../utils/useOutAnimation';

export type OutAnimationProps = Omit<OutAnimationBag, 'handleClose'> & {
    children: ReactElement;
    className?: string;
};

export const OutAnimation = ({
    children,
    collapsed,
    slided,
    handleCollapse,
    unmount,
    className,
}: OutAnimationProps) => {
    return (
        <Collapse in={collapsed} enter={false} orientation="vertical" onExited={unmount} className={className}>
            <Slide direction="right" in={slided} onExited={handleCollapse}>
                {children}
            </Slide>
        </Collapse>
    );
};
