import { Fetcher, useQuery } from 'react-suspended-query';

import { GlobalCache } from './GlobalCache';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createAsset = <T extends (...args: any[]) => any>(key: string, fetcher: T) => {
    const useAsset = (...args: Parameters<T>): Awaited<ReturnType<T>> => {
        const data = useQuery(
            [key, ...args],
            ((_key: string, ...args: Parameters<T>) => fetcher(...args)) as unknown as Fetcher<
                unknown,
                [string, ...Parameters<T>]
            >,
            GlobalCache,
        );

        return data as Awaited<ReturnType<T>>;
    };

    return useAsset;
};
