import React, { ReactNode, useState } from 'react';
import { styled } from '@mui/material';

import { DefaultTooltip } from './MaterialTheme';

type Props = {
    title: string | false;
    children: ReactNode;
};

const Wrapper = styled('span', {
    label: 'Wrapper',
})({
    display: 'inline-flex',
});

// Component must be used when GWT popup is opened, otherwise DefaultTooltip will remain on the screen
export const ControlledTooltip = ({ title, children }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(false);
    };

    return (
        <DefaultTooltip
            disableTouchListener
            title={title}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            onClick={handleClick}
        >
            <Wrapper>{children}</Wrapper>
        </DefaultTooltip>
    );
};
