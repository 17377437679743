import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum ContactType {
    ALLC = 'ALLC',
    CMPM = 'CMPM',
    CRFM = 'CRFM',
    CRGD = 'CRGD',
    CRTM = 'CRTM',
    INCM = 'INCM',
    INPM = 'INPM',
    CRQM = 'CRQM',
    CUSTOMER = 'CUST.CON',
    AUTHORIZED_PERSON = 'AUTH.PERS',
}

const contactTypeI18n = createDefaultI18n<EnumI18n<typeof ContactType>>('enums.ContactType', {
    ALLC: 'Common contact',
    CMPM: 'Compound manager',
    CRFM: 'Financial manager',
    CRGD: 'General director',
    CRTM: 'Transport manager',
    INCM: 'Claims manager',
    INPM: 'Policy manager',
    CRQM: 'Quality manager',
    CUSTOMER: 'Customer contact person',
    AUTHORIZED_PERSON: 'Authorized person',
});

export const ContactTypeBundle: EnumBundle<typeof ContactType> = {
    value: ContactType,
    defaultI18n: contactTypeI18n,
};
