import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';
import { createEnumValueValidator } from '../utils/createEnumValueValidator';

export enum OfferPriceType {
    TRQ = 'TRQ',
    DAY = 'DAY',
    CRG = 'CRG',
    KM = 'KM',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof OfferPriceType>>('enums.OfferPriceType', {
    TRQ: 'Transportation',
    DAY: 'Day',
    CRG: 'Cargo',
    KM: 'Km',
});

export const OfferPriceTypeBundle: EnumBundle<typeof OfferPriceType> = {
    value: OfferPriceType,
    defaultI18n,
};

export type OfferPricePath = 'price_per_cargo' | 'price_per_day' | 'price_km';

export const offerPriceTypeToPathMap: Record<OfferPriceType, OfferPricePath> = {
    TRQ: 'price_per_cargo',
    DAY: 'price_per_day',
    CRG: 'price_per_cargo',
    KM: 'price_km',
};

export const validateOfferPriceType = createEnumValueValidator(OfferPriceType);
