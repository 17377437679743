import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum OptGoal {
    KM = 'KM',
    PRICE = 'PRICE',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof OptGoal>>('enums.OptGoal', {
    KM: 'Minimize empty km',
    PRICE: 'Optimize price',
});

export const OptGoalBundle: EnumBundle<typeof OptGoal> = {
    defaultI18n,
    value: OptGoal,
};
