import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum OrderStatus {
    ALL = 'ALL',
    CAN = 'CAN',
    ORD = 'ORD',
    DLV = 'DLV',
    INV = 'INV',
    DRF = 'DRF',
    LOAD = 'LOAD',
    ACT = 'ACT',
    END = 'END',
    PAI = 'PAI',
    PINV = 'PINV',
    PPAI = 'PPAI',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof OrderStatus>>('enums.OrderStatus', {
    ALL: 'All',
    CAN: 'Cancelled',
    ORD: 'Ordered',
    DLV: 'Delivered',
    INV: 'Invoiced',
    DRF: 'Draft',
    LOAD: 'Loaded',
    ACT: 'Active',
    END: 'Ended',
    PAI: 'Paid',
    PINV: 'Partially invoiced',
    PPAI: 'Partially paid',
});

export const OrderStatusBundle: EnumBundle<typeof OrderStatus> = {
    value: OrderStatus,
    defaultI18n,
    icons: {
        DRF: { path: 'StatusIcons/NEW.svg', color: '' },
        PAI: { path: 'StatusIcons/PAID.svg', color: '' },
    },
};
