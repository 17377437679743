import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

enum RentalOrdersStatus {
    ORD = 'ORD',
    DLV = 'DLV',
    LOAD = 'LOAD',
    CAN = 'CAN',
}

const rentalOrdersStatusI18n = createDefaultI18n<EnumI18n<typeof RentalOrdersStatus>>('enums.RentalOrdersStatus', {
    ORD: 'Ordered',
    DLV: 'Delivered',
    LOAD: 'Loaded',
    CAN: 'Cancelled',
});

export const RentalOrdersStatusBundle: EnumBundle<typeof RentalOrdersStatus> = {
    value: RentalOrdersStatus,
    defaultI18n: rentalOrdersStatusI18n,
    icons: true,
};
