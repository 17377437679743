import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

enum AuthLetterStatus {
    NEW = 'NEW',
    CONF = 'CONF',
    SENT = 'SENT',
    CAN = 'CAN',
}

const AuthLetterStatusI18n = createDefaultI18n<EnumI18n<typeof AuthLetterStatus>>('enums.AuthLetterStatus', {
    NEW: 'New',
    CONF: 'Confirmed',
    SENT: 'Sent',
    CAN: 'Cancelled',
});

export const AuthLetterStatusBundle: EnumBundle<typeof AuthLetterStatus> = {
    value: AuthLetterStatus,
    defaultI18n: AuthLetterStatusI18n,
    icons: true,
};
