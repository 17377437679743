import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum NoteVisibilityEnum {
    PRI = 'PRI',
    GRP = 'GRP',
    PAR = 'PAR',
}

const NoteVisibilityI18n = createDefaultI18n<EnumI18n<typeof NoteVisibilityEnum>>('enums.NoteVisibilities', {
    PRI: 'Only my company',
    GRP: 'Our company group',
    PAR: 'Counterparty',
});

export const NoteVisibility: EnumBundle<typeof NoteVisibilityEnum> = {
    value: NoteVisibilityEnum,
    defaultI18n: NoteVisibilityI18n,
};
