type EnumValidationSuccess<T extends Record<string, unknown>> = {
    success: true;
    value: T[keyof T];
};

type EnumValidationError = {
    success: false;
    value: undefined;
};

export type EnumValidationResult<T extends Record<string, unknown>> = EnumValidationSuccess<T> | EnumValidationError;

export const createEnumValueValidator =
    <T extends Record<string, unknown>>(enumerable: T) =>
    (value: unknown): EnumValidationResult<T> => {
        if (typeof enumerable !== 'object' || enumerable === null || !Object.values(enumerable).includes(value)) {
            return {
                success: false,
                value: undefined,
            };
        }

        return {
            success: true,
            value: value as T[keyof T],
        };
    };
