import { CacheGroup } from 'react-suspended-query';
import { IServiceCallback } from '@alcs/gwt';
import { createSafeContext, useSafeContext } from '@sirse-dev/safe-context';

export type QueryContextType = {
    FormCache: CacheGroup;
    callback: IServiceCallback;
};

export const QueryContext = createSafeContext<QueryContextType>();

export const useQueryContext = () => useSafeContext(QueryContext);
