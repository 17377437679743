import PopupController from './PopupController';
import { ConstantPopupData } from './PopupData';
import { PopupLayerController } from './PopupLayerController';
import { generateUUID } from './Utils';

export type PopupKey = string;

export class PopupsManager {
    public readonly layerController: PopupLayerController = new PopupLayerController();
    private readonly popups: Map<PopupKey, PopupController<unknown>> = new Map<PopupKey, PopupController<unknown>>();
    private readonly refresh: (popups: PopupController<unknown>[]) => void;

    public constructor(refresh: (popups: PopupController<unknown>[]) => void) {
        this.refresh = refresh;
    }

    private onDataChanged = () => {
        this.refresh(Array.from(this.popups.values()));
    };

    public getOrAdd<T>(constantData: ConstantPopupData<T>, key: PopupKey): PopupController<T> {
        const getted = this.get<T>(key);
        if (getted) {
            return getted;
        }
        return this.add<T>(constantData, key);
    }

    public add<T>(constantData: ConstantPopupData<T>, key?: PopupKey): PopupController<T> {
        key = key ?? generateUUID();
        const controller = new PopupController<T>(constantData, key, this.onDataChanged, this);
        this.popups.set(key, controller);
        return controller;
    }

    public delete(key: PopupKey) {
        this.popups.delete(key);
        this.onDataChanged();
    }

    public get<T>(key: PopupKey): PopupController<T> | undefined {
        return this.popups.get(key) as PopupController<T>;
    }
}
