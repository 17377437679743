import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum FileAccessRole {
    EXECUTIVES = 'EXECUTIVES',
    FINANCE = 'FINANCE',
    QUALITY = 'QUALITY',
}

const fileAccessRoleI18n = createDefaultI18n<EnumI18n<typeof FileAccessRole>>('enums.FileAccessRole', {
    EXECUTIVES: 'Executives',
    FINANCE: 'Finance managers',
    QUALITY: 'Quality managers',
});

export const fileAccessRoleBundle: EnumBundle<typeof FileAccessRole> = {
    value: FileAccessRole,
    defaultI18n: fileAccessRoleI18n,
};
