import { joinPaths } from './TextUtils';

export const flatObject = (object: object) => {
    const queue: [path: string, value: object][] = [['', object]];

    const output: [path: string, value: unknown][] = [];

    while (queue.length > 0) {
        const [path, bigObject] = queue.shift()!;

        Object.entries(bigObject).forEach(([subpath, value]) => {
            const arrayToPush = typeof value === 'object' && value !== null ? queue : output;
            arrayToPush.push([joinPaths(path, subpath), value]);
        });
    }

    return output;
};
