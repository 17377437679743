/**
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 * @author Laurynas Duburas
 */
import React, { CSSProperties, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { InputAdornment, Typography } from '@mui/material';
import TextField, { OutlinedTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import {
    FieldValidator,
    FormProxyContext,
    useFieldError,
    useFieldTouched,
    useFieldValidator,
    useFieldValue,
    useFormContext,
} from '@reactive-forms/core';
import clsx from 'clsx';
import { Pxth } from 'pxth';
import { intercept } from 'stocked';

import { isDisplayableValue } from '../../utils/isDisplayableValue';
import mergeRefs from '../../utils/mergeRefs';
import { useRefCallback } from '../../utils/useRefCallback';
import { FieldRegistryContext } from '../ErrorsAlert';
import { useFieldPrivileges } from '../FormPrivilegesContext';
import { ErrorIcon } from '../icons/ErrorIcon';
import Skeleton from '../Skeleton';

export type FieldRenderer<T> = (props: ViewProps<T>) => React.ReactNode;

export type AllFieldBaseProps<T> = ViewProps<T> & FieldBaseProps<T>;

export type ViewProps<T> = Omit<FieldBaseProps<T>, 'name' | 'children' | 'validate' | 'onValueChanged'> &
    ExclusiveViewProps<T>;

/**
 * @property {T}                        value                     - value of field
 * @property {string}                   stringifiedValue          - value in string format
 * @property {(value: T) => void}       setFieldValue             - function to set value of field
 * @property {(value: string) => void}  setStringifiedValue       - function to set value of field in string format
 * @property {?string}                  error                     - error message after validation
 * @property {boolean}                  touched                   - boolean that shows touched field or not
 * @property {boolean}                  editable                  - boolean that shows editable field or not
 * @property {boolean}                  focused                   - boolean that shows focused field or not
 */
interface ExclusiveViewProps<T> {
    value: T;
    stringifiedValue: string;
    typedValue: string;
    setFieldValue: (value: T) => void;
    setStringifiedValue: (value: string) => void;
    error?: string;
    touched: boolean;
    focused: boolean;
}

/**
 * @property {(value: T) => string}                 valueToString   - converts value to string
 * @property {(value: string) => T}                 stringToValue   - converts string to value
 * @property {(value: string) => false | string}    isInvalid       - checks if stringifiedValue is valid to convert it to value
 */
export interface ValueConverter<T> {
    valueToString: (value: T) => string;
    stringToValue: (value: string) => T;
    isInvalid: (value: string) => false | string;
}

export interface FieldBaseProps<T> {
    /** name for field, which is used as path to the value */
    name: Pxth<T>;
    /** field for setting meta data */
    metaName?: Pxth<unknown>;
    /** disables setting "touched" for field */
    disableTouched?: boolean;
    /** makes field selected when user clicks on it */
    selectOnFocus?: boolean;
    /** disables field. Shows disabled field */
    disabled?: boolean;
    /** function to render field */
    children?: FieldRenderer<T>;
    /** function that is called when value changes */
    onChange?: (e: React.ChangeEvent<{ value: string }>) => void;
    /** function that is called when field blurred */
    onBlur?: (e: React.FocusEvent) => void;
    /** function that is called when field focused */
    onFocus?: (e: React.FocusEvent) => void;
    /** function that is called when clicked on field */
    onClick?: (e: React.MouseEvent) => void;
    /** function to validate field */
    validate?: FieldValidator<T>;
    /** object to convert value to string and string to value @see ValueConverter */
    converter?: ValueConverter<T>;
    /** used to forcibly make field not editable. If editable is true, field uses privileges */
    editable?: boolean;
    /** do not validate field */
    skipValidation?: boolean;
    /** custom properties for default field render */
    textFieldProps?: Partial<TextFieldProps>;
    /** function that calls when field is not editable. It takes stringifiedValue and returns modified stringifiedValue. */
    nonEditableValueMask?: (value: string) => React.ReactNode;
    /** reference to field */
    inputRef?: React.Ref<HTMLInputElement | undefined> | React.MutableRefObject<HTMLInputElement | undefined>;
    /** function that is called after value of field was changed */
    onValueChanged?: (newValue: T) => void;
    /** sets width of field */
    width?: number;
    setValueOnBlur?: boolean;
    /** transform error string */
    errorMask?: (error?: string | Record<string, unknown>) => string;
    /** automatically focus field on first render */
    autoFocus?: boolean;
    /** label for the error panel display */
    displayLabel?: string;
    /** if true, the error icon will appear at the start of the field, instead of the end */
    errorAtStart?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DefaultConverter: ValueConverter<any> = {
    valueToString: value => value,
    stringToValue: value => value,
    isInvalid: () => false,
};

const identityCallback = <V,>(value: V) => value;

export const useRealFieldName = <T,>(name: Pxth<unknown>): Pxth<T> => {
    const proxy = useContext(FormProxyContext);

    const normalName = intercept(proxy, name, identityCallback, proxy?.getNormalPath ?? identityCallback, [
        name,
    ]) as Pxth<T>;

    return normalName;
};

export const useFieldBase = <T,>(props: Omit<FieldBaseProps<T>, 'children'>): ViewProps<T> => {
    const {
        name,
        editable = true,
        disabled,
        onChange,
        textFieldProps,
        converter = DefaultConverter,
        selectOnFocus,
        onFocus,
        setValueOnBlur = true,
        onBlur,
        onValueChanged,
        autoFocus,
        inputRef,
        skipValidation,
        validate: validateFn,
        disableTouched,
        displayLabel,
        metaName = name as Pxth<unknown>,
    } = props;

    const [value, setValue] = useFieldValue<T>(name);
    const [error, setError] = useFieldError(metaName);
    const [touched] = useFieldTouched(metaName);
    const { values, getFieldValue, setFieldTouched, validateField } = useFormContext();

    const normalName = useRealFieldName(metaName);

    const setTouched = disableTouched
        ? () => {
              //
          }
        : setFieldTouched;

    const { isEditable: editablePrivilege, disabled: disablePrivilege } = useFieldPrivileges(normalName);

    const { addToRegistry, removeFromRegistry } = useContext(FieldRegistryContext);

    const focusInputRef = useRef<HTMLInputElement>();

    const isEditable = Boolean(editablePrivilege) && editable;
    const isDisabled = Boolean(disablePrivilege) || Boolean(disabled);

    const [focused, setFocused] = useState(false);
    const [typedValue, setTypedValue] = useState(converter.valueToString(value));

    const converterError = converter.isInvalid(typedValue);

    const syncTypedValue = useRefCallback((value: T) => {
        const newTypedValue = converter.valueToString(value);
        if (!converter.isInvalid(newTypedValue)) {
            setTypedValue(newTypedValue);
        }
    });

    const handleChange = useCallback(
        (e: React.ChangeEvent<{ value: string }>) => {
            setTypedValue(e.target.value);
            onChange?.(e);
        },
        [onChange],
    );

    const validate = useRefCallback(() => {
        const value = getFieldValue<T>(name)!;

        if (!isEditable || skipValidation || isDisabled) {
            return Promise.resolve();
        }
        const error = converter.isInvalid(typedValue);
        if (error) {
            return Promise.resolve(error);
        } else if (validateFn) {
            return validateFn(value);
        }
        return Promise.resolve();
    });

    useFieldValidator({ name: metaName, validator: validate });

    useEffect(() => {
        addToRegistry(normalName, {
            ref: focusInputRef,
            label: displayLabel,
        });

        return () => removeFromRegistry(normalName);
    }, [addToRegistry, displayLabel, normalName, removeFromRegistry]);

    const valueChangedCallback = useRefCallback(onValueChanged);

    useEffect(() => values.watch(name, valueChangedCallback), [name, values, valueChangedCallback]);

    useEffect(() => values.watch<T>(name, newValue => syncTypedValue(newValue)), [name, syncTypedValue, values]);

    useLayoutEffect(() => {
        setTimeout(() => {
            if (autoFocus) {
                focusInputRef.current?.focus();
            }
        }, 10);
    }, [autoFocus]);

    const handleFocus = (e: React.FocusEvent) => {
        setFocused(true);
        setTypedValue(converterError || !setValueOnBlur ? typedValue : converter.valueToString(value));
        if (selectOnFocus) {
            (e.target as HTMLInputElement).select();
        }
        onFocus?.(e);
    };

    const handleBlur = async (e: React.FocusEvent) => {
        setTouched(metaName, { $touched: true });
        if (setValueOnBlur && !converterError) {
            setValue(converter.stringToValue(typedValue));
        } else {
            const error = await validateField(metaName);
            setError(error);
        }
        setFocused(false);
        onBlur?.(e);
    };

    return {
        ...props,
        stringifiedValue: focused || converterError ? typedValue : converter.valueToString(value),
        value,
        touched: !!touched?.$touched,
        focused,
        error: error?.$error,
        typedValue,
        setStringifiedValue: newStringifiedValue => setTypedValue(newStringifiedValue),
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        setFieldValue: async (newValue: T) => {
            await setValue(newValue);
            setTouched(metaName, { $touched: true });
        },
        inputRef: mergeRefs(inputRef, focusInputRef),
        textFieldProps,
        editable: isEditable,
        disabled: isDisabled,
    };
};

/**
 * Component for creating fields. Adds functionality for privileges, setting value, validation.
 * Includes optimization for field:
 * <ul>
 *  <li>Uses shallow comparison for value, error, etc.</li>
 *  <li>Sets value into context only when field is blured, typed value saves into component state</li>
 * </ul>
 * **NOTE:** If you want to add state for your field, use withField.
 * @component
 */
export const FieldBase = <T,>({ children, ...otherProps }: FieldBaseProps<T>) => {
    const field = useFieldBase<T>(otherProps);

    return children ? (
        <React.Fragment>{children(field)}</React.Fragment>
    ) : (
        <DefaultFieldView {...(field as ViewProps<unknown>)} />
    );
};

export default FieldBase;

export const DefaultFieldView = (props: ViewProps<unknown>) => {
    const { editable, textFieldProps } = props;
    return editable ? (
        <EditableFieldView {...props} />
    ) : (
        <NonEditableFieldView
            {...props}
            variant={textFieldProps?.variant}
            style={textFieldProps?.style}
            className={clsx(textFieldProps?.className, textFieldProps?.inputProps?.className)}
        />
    );
};

export const DEFAULT_TEXTFIELD_VARIANT = 'outlined';

export const fieldMargins: Record<NonNullable<TextFieldProps['variant']>, CSSProperties> = {
    standard: {
        paddingTop: 2,
        paddingBottom: 2,
        alignItems: 'center',
    },
    outlined: {
        paddingTop: 8,
        paddingBottom: 4,
        alignItems: 'center',
    },
    filled: {},
};

type NonEditableFieldViewProps = Pick<
    ViewProps<unknown>,
    'value' | 'converter' | 'nonEditableValueMask' | 'width' | 'textFieldProps'
> & {
    variant?: TextFieldProps['variant'];
    style?: CSSProperties;
    className?: string;
};

export const NonEditableFieldView = ({
    value,
    converter = {
        isInvalid: () => false,
        stringToValue: value => value,
        valueToString: value => String(value),
    },
    variant = DEFAULT_TEXTFIELD_VARIANT,
    style,
    className,
    width,
    nonEditableValueMask = value => value,
    textFieldProps,
}: NonEditableFieldViewProps) => (
    <Typography
        className={className}
        style={{
            ...(textFieldProps?.margin !== 'none' && fieldMargins[variant]),
            ...style,
            width: textFieldProps?.fullWidth ? '100%' : width,
        }}
        component="span"
    >
        {isDisplayableValue(value) ? (
            nonEditableValueMask(converter!.valueToString(value))
        ) : (
            <Skeleton multiline={textFieldProps?.multiline} />
        )}
    </Typography>
);

export const EditableFieldView = ({
    stringifiedValue,
    textFieldProps,
    width,
    onChange,
    onBlur,
    onFocus,
    onClick,
    disabled,
    inputRef,
    touched,
    error,
    errorAtStart,
}: ViewProps<unknown>) => (
    <StyledTextField
        value={stringifiedValue}
        {...(textFieldProps as Omit<OutlinedTextFieldProps, 'variant' | 'error'>)}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={onClick}
        disabled={disabled}
        inputRef={inputRef}
        touched={touched}
        error={error}
        errorAtStart={errorAtStart}
        style={{
            ...textFieldProps?.style,
            width,
        }}
        autoComplete="off"
    />
);

export type StyledTextFieldProps = Partial<Omit<TextFieldProps, 'error'>> & {
    error?: string;
    touched?: boolean;
    errorAtStart?: boolean;
};

export const StyledTextField = ({ error, touched, InputProps, errorAtStart, ...other }: StyledTextFieldProps) => (
    <TextField
        variant={DEFAULT_TEXTFIELD_VARIANT}
        margin="dense"
        error={touched && Boolean(error)}
        {...other}
        InputProps={{
            endAdornment: !errorAtStart && touched && error && (
                <InputAdornment position="end">
                    <ErrorIcon error={error} />
                </InputAdornment>
            ),
            startAdornment: errorAtStart && touched && error && (
                <InputAdornment position="start">
                    <ErrorIcon error={error} />
                </InputAdornment>
            ),
            ...InputProps,
        }}
    />
);

/**
 * withField used to use component with access to viewProps @see ViewProps
 * @component
 * @param WrappedComponent - component that should be wrapped in withField
 * @param defaultProps     - default FieldBase properties
 * @wraps FieldBase
 */
export function withField<Value, Props extends FieldBaseProps<Value>>(
    WrappedComponent: React.ComponentType<Props>,
    defaultProps?: Partial<FieldBaseProps<Value>>,
) {
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    return class extends React.Component<Omit<Props, keyof ExclusiveViewProps<Value>>> {
        static displayName = `withField(${wrappedComponentName})`;

        public render(): React.ReactNode {
            return (
                <FieldBase {...defaultProps} {...(this.props as FieldBaseProps<Value>)}>
                    {viewProps => <WrappedComponent {...defaultProps} {...(this.props as Props)} {...viewProps} />}
                </FieldBase>
            );
        }
    };
}
