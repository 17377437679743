import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum ServicePlanCode {
    BASIC = 'A',
    ADVANCED = 'B',
    PROFESSIONAL = 'C',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof ServicePlanCode>>('enums.ServicePlanCode', {
    BASIC: 'Basic',
    ADVANCED: 'Advanced',
    PROFESSIONAL: 'Professional',
});

export const ServicePlanCodeBundle: EnumBundle<typeof ServicePlanCode> = {
    value: ServicePlanCode,
    defaultI18n,
};
