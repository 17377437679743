import React, { createContext, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import {
    ErrorPopupGroup,
    InlineErrorPopupGroup,
    OutAnimation,
    useCloseHandler,
    useOutAnimation,
    usePopup,
} from '@alcs/popups';
import { Alert as MuiAlert, Collapse, styled } from '@mui/material';
import { FormConfig, useFieldError, useFieldTouched } from '@reactive-forms/core';
import { Pxth, pxthToString } from 'pxth';

import { Alert } from './Alert';
import { ResizableContainer } from './ResizableContainer';
import { flatObject } from '../utils/flatObject';

export type FieldMeta = {
    label?: string;
    ref?: MutableRefObject<HTMLInputElement | undefined>;
};

export type FieldRegistry = Record<string, FieldMeta>;

export type FieldRegistryContextType = {
    addToRegistry: <V>(name: Pxth<V>, field: FieldMeta) => void;
    removeFromRegistry: <V>(name: Pxth<V>) => void;
};

export const FieldRegistryContext = createContext<FieldRegistryContextType>({
    addToRegistry: () => {
        /** empty */
    },
    removeFromRegistry: () => {
        /** empty */
    },
});

export const defaultI18n = createDefaultI18n('components.ErrorsAlert', {
    title: 'Some entered data is incorrect',
});

type FormError = {
    text: string;
    ref?: MutableRefObject<HTMLInputElement>;
};

export type ErrorsAlertBoxProps = {
    errors: Array<FormError>;
    close: () => void;
    inline?: boolean;
};

export type ErrorsAlertPopupProps = {
    errors: Array<FormError>;
};

const ErrorsAlertPopupRoot = styled('div', {
    label: 'ErrorsAlertPopupRoot',
})({
    pointerEvents: 'all',
    marginBottom: '5px',
    '--resize-icon-color': '#f4988d',
});

export const ErrorsAlertPopup = ({ errors }: ErrorsAlertPopupProps) => {
    const { handleClose, ...other } = useOutAnimation();

    return (
        <OutAnimation {...other}>
            <ErrorsAlertPopupRoot>
                <ResizableContainer
                    minConstraints={[400, 100]}
                    maxConstraints={[1000, 600]}
                    initialWidth={500}
                    initialHeight={150}
                >
                    <ErrorsAlertBox errors={errors} close={handleClose} />
                </ResizableContainer>
            </ErrorsAlertPopupRoot>
        </OutAnimation>
    );
};

const InlineErrorsAlertPopup = ({ errors }: ErrorsAlertPopupProps) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const unmount = useCloseHandler(handleClose);

    useEffect(() => unmount, [unmount]);

    return (
        <Collapse in={open} appear orientation="vertical" onExited={unmount}>
            <ErrorsAlertBox errors={errors} close={handleClose} inline />
        </Collapse>
    );
};

const StyledAlert = styled(Alert, {
    label: 'StyledAlert',
    shouldForwardProp: propName => propName !== 'isInline',
})<{ isInline: boolean | undefined }>(({ isInline }) => ({
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    ...(!isInline && {
        boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    }),
}));

const ErrorLink = styled('div', {
    label: 'ErrorLink',
})({
    display: 'block',
    margin: '5px 0',
    textDecoration: 'underline',
    cursor: 'pointer',
    ':hover': {
        color: 'black',
    },
});

const ErrorsAlertBox = ({ errors, close, inline }: ErrorsAlertBoxProps) => {
    const i18n = useI18n(defaultI18n);

    return (
        <StyledAlert isInline={inline} onClose={close} title={i18n.title} severity="E">
            {errors.map(({ text, ref }, key) => (
                <ErrorLink onClick={() => ref?.current?.focus()} key={key}>
                    {text}
                </ErrorLink>
            ))}
        </StyledAlert>
    );
};

const getErrorText = (meta: FieldMeta | undefined, error: string) => (meta?.label ? `${meta?.label}: ` : '') + error;

export type UseErrorsAlertBag<T extends object> = [
    Pick<FormConfig<T>, 'onValidationFailed' | 'onValidationSucceed' | 'onReset'>,
    FieldRegistryContextType,
];

export const useErrorsAlert = <T extends object>(inline = false): UseErrorsAlertBag<T> => {
    const [openPopup, closePopup] = usePopup(
        inline ? InlineErrorsAlertPopup : ErrorsAlertPopup,
        {},
        inline ? InlineErrorPopupGroup : ErrorPopupGroup,
    );
    const registryRef = useRef<FieldRegistry>({});

    const onValidationFailed = useCallback(
        (errors: object) => {
            const output = flatObject(errors).filter(([, error]) => Boolean(error)) as [path: string, error: string][];

            const parsedErrors = output.map(([rawPath, error]) => {
                const path = rawPath.replace(/\.\$error/g, '');

                return {
                    text: getErrorText(registryRef.current[path], error),
                    ref: registryRef.current[path]?.ref as MutableRefObject<HTMLInputElement> | undefined,
                };
            });

            openPopup({
                errors: parsedErrors,
            });
        },
        [openPopup],
    );

    const addToRegistry = useCallback(
        <V,>(name: Pxth<V>, field: FieldMeta) => (registryRef.current[pxthToString(name) as string] = field),
        [],
    );
    const removeFromRegistry = useCallback(
        <V,>(name: Pxth<V>) => delete registryRef.current[pxthToString(name) as string],
        [],
    );

    useEffect(() => {
        return () => {
            closePopup();
        };
    }, [closePopup]);

    return [
        { onValidationFailed, onValidationSucceed: closePopup, onReset: closePopup },
        {
            addToRegistry,
            removeFromRegistry,
        },
    ];
};

export type FieldErrorProps<T> = {
    path: Pxth<T>;
    useTouched?: boolean;
    className?: string;
};

export const FieldErrorAlert = <T,>({ path, useTouched = true, className }: FieldErrorProps<T>) => {
    const [error] = useFieldError(path);
    const [touched] = useFieldTouched(path);

    const displayError = error?.$error && (!useTouched || touched?.$touched);

    if (!displayError) {
        return null;
    }

    return (
        <Collapse in appear>
            <MuiAlert severity="error" className={className}>
                {error?.$error}
            </MuiAlert>
        </Collapse>
    );
};
