import React from 'react';
import { Find_classifiers } from '@alcs/beans';
import { AlsFallback } from '@alcs/core/components/containers/AlsFallback';
import { useLanguages } from '@alcs/core/utils/useLanguages';

import { FieldBaseProps } from '@alcs/core/components/fields/Fields';
import { LanguageView } from '@alcs/core/components/fields/LanguageField';
import { SelectField } from '@alcs/core/components/fields/SelectField';

export type LanguageFieldProps = FieldBaseProps<Find_classifiers>;

export const LanguageField = (props: LanguageFieldProps) => {
    const { data: languages, error } = useLanguages();

    if (error) {
        return <div>{String(error)}</div>;
    }

    if (!languages) {
        return <AlsFallback />;
    }

    return (
        <SelectField
            {...props}
            textFieldProps={{
                variant: 'standard',
                ...props.textFieldProps,
            }}
            items={languages}
            getValueKey={value => value.value_code}
            renderItem={({ value_code, value_description }) => (
                <LanguageView code={value_code} name={value_description} />
            )}
        />
    );
};
