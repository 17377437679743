import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum SupplierPriceType {
    TRQ = 'TRQ',
    KM = 'KM',
    VEH = 'VEH',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof SupplierPriceType>>('enums.SupplierPriceType', {
    TRQ: 'Transportation',
    KM: 'Km',
    VEH: 'Vehicle',
});

export const SupplierPriceTypeBundle: EnumBundle<typeof SupplierPriceType> = {
    value: SupplierPriceType,
    defaultI18n,
};
