import { useEffect, useState } from 'react';
import { Find_classifiers } from '@alcs/beans';
import { ClassifierDomains } from '@alcs/enums';
import { Als_utl, emptyServiceCallback } from '@alcs/services';

export const useLanguages = () => {
    const [data, setData] = useState<Find_classifiers[] | undefined>(undefined);
    const [error, setError] = useState<unknown | undefined>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            const service = new Als_utl(emptyServiceCallback);

            try {
                const fetchedData = await service.find_classifiers(ClassifierDomains.LANGUAGE);
                setData(fetchedData);
            } catch (_error: unknown) {
                setError(_error);
            }
        };

        fetchData();
    }, []);

    return { data, error };
};
