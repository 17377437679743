import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum CompanyType {
    SYS = 'SYS',
    CR = 'CR',
    MF = 'MF',
    CMP = 'CMP',
    DUM = 'DUMMY',
    SP = 'SP',
    PERSONAL = 'PER',
    DLR = 'DLR',
}

const companyTypeI18n = createDefaultI18n<EnumI18n<typeof CompanyType>>('enums.CompanyType', {
    SYS: 'System',
    CR: 'Carrier',
    MF: 'OEM',
    CMP: 'Compound',
    DUM: 'Private contact',
    SP: 'Service provider',
    PERSONAL: 'Personal',
    DLR: 'Dealer',
});

export const CompanyTypeBundle: EnumBundle<typeof CompanyType> = {
    value: CompanyType,
    defaultI18n: companyTypeI18n,
};
