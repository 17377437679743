import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum TimeScale {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    DAY = 'DAY',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof TimeScale>>('enums.TimeScale', {
    YEAR: 'Year',
    MONTH: 'Month',
    WEEK: 'Week',
    DAY: 'Day',
});

export const TimeScaleBundle: EnumBundle<typeof TimeScale> = { value: TimeScale, defaultI18n };
