import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

enum DepositPaymentType {
    CARD = 'CARD',
    BTRAN = 'BTRAN',
}

const DepositPaymentTypeI18n = createDefaultI18n<EnumI18n<typeof DepositPaymentType>>('enums.DepositPaymentType', {
    CARD: 'I will pay online with the card',
    BTRAN: 'I will pay offline via bank transfer',
});

export const DepositPaymentTypeBundle: EnumBundle<typeof DepositPaymentType> = {
    value: DepositPaymentType,
    defaultI18n: DepositPaymentTypeI18n,
};
