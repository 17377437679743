import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum UnavailableVinReasons {
    NOTONSITE = 'NOTONSITE',
    CONTACTUNKNOWN = 'CONTACTUNKNOWN',
    NOTREADYFORPICKUP = 'NOTREADYFORPICKUP',
    NOKEYS = 'NOKEYS',
    NOTREADYTODRIVE = 'NOTREADYTODRIVE',
    NOTREADYTOMOVE = 'NOTREADYTOMOVE',
    OTHER = 'OTHER',
    EMPTYRUN = 'EMPTYRUN',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof UnavailableVinReasons>>('enums.UnavailableVinReasons', {
    NOTONSITE: 'Vehicle cannot be found/ not at the location',
    CONTACTUNKNOWN: 'Contact person at pick-up lo-cation not known',
    NOTREADYFORPICKUP: 'Vehicle not ready for pick-up (reason in free text)',
    NOKEYS: 'Vehicle key not available',
    NOTREADYTODRIVE: 'Vehicle not roadworthy',
    NOTREADYTOMOVE: 'Vehicle not ready to roll',
    OTHER: 'Other reason for an incident (reason in free text)',
    EMPTYRUN: 'Empty run',
});

export const UnavailableVinReasonsBundle: EnumBundle<typeof UnavailableVinReasons> = {
    value: UnavailableVinReasons,
    defaultI18n,
};
