import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum SupplierPriceDistances {
    ALL = 'ALL',
    SHORT = 'S',
    MID = 'M',
    LONG = 'L',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof SupplierPriceDistances>>('enums.SupplierPriceDistances', {
    ALL: 'All',
    SHORT: 'Short (>200 km)',
    MID: 'Mid (200-600 km)',
    LONG: 'Long (<600 km)',
});

export const SupplierPriceDistancesBundle: EnumBundle<typeof SupplierPriceDistances> = {
    value: SupplierPriceDistances,
    defaultI18n,
};
