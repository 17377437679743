import React, { PropsWithChildren } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { styled } from '@mui/material';

import { SnackbarContainerPosition } from '../SnackbarGroup';

export type SnackbarTransitionGroupProps = PropsWithChildren<{
    position: SnackbarContainerPosition;
}>;

const SnackbarContainer = styled(TransitionGroup, {
    label: 'SnackbarContainer',
    shouldForwardProp: propName => propName !== 'position',
})<{ position: SnackbarContainerPosition }>(({ position }) => ({
    position: 'fixed',
    // FIXME: z-index is 3 in order to overlap reactPopovers - remove this line after migrating to reactive-popups
    zIndex: '3',
    display: 'flex',
    padding: '14px 20px',
    pointerEvents: 'none',
    ...(position === 'top-left' && {
        top: '0',
        left: '0',
        flexDirection: 'column',
    }),
    ...(position === 'bottom-left' && {
        bottom: '0',
        left: '0',
        flexDirection: 'column-reverse',
    }),
}));

export const SnackbarTransitionGroup = ({ children, position }: SnackbarTransitionGroupProps) => {
    return <SnackbarContainer position={position}>{children}</SnackbarContainer>;
};
