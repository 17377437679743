import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum RequestType {
    CON = 'CON',
    SUP = 'SUP',
    PER = 'PER',
    DLR = 'DLR',
    STD = 'STD',
    TND = 'TND',
}

const RequestTypeI18n = createDefaultI18n<EnumI18n<typeof RequestType>>('enums.RequestType', {
    CON: 'Contract',
    SUP: 'For supplier',
    PER: 'Personal',
    DLR: 'Single units',
    STD: 'SPOT',
    TND: 'Tender',
});

export const RequestTypeBundle: EnumBundle<typeof RequestType> = {
    value: RequestType,
    defaultI18n: RequestTypeI18n,
};
