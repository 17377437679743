import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum LoadScheduleStatus {
    CAN = 'CAN',
    UNSCH = 'UNSCH',
    SCH = 'SCH',
    LOAD = 'LOAD',
    DLV = 'DLV',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof LoadScheduleStatus>>('enums.LoadScheduleStatus', {
    CAN: 'Canceled',
    UNSCH: 'Not assigned to truck',
    SCH: 'Assigned to truck',
    LOAD: 'Loaded',
    DLV: 'Delivered',
});

export const LoadScheduleStatusBundle: EnumBundle<typeof LoadScheduleStatus> = {
    value: LoadScheduleStatus,
    defaultI18n,
    icons: true,
};
