import React, { useSyncExternalStore } from 'react';
import { formatDateTime, formatDateTimeUTC } from '@alcs/core/utils/DateUtils';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import { Button, styled, Typography } from '@mui/material';

import { ISOImage } from './ISOImage';
import iso9001 from '../images/ISO_9001.png';
import iso9001Big from '../images/ISO_9001_big.png';
import iso14001 from '../images/ISO_14001.png';
import iso14001Big from '../images/ISO_14001_big.png';
import iso27001 from '../images/ISO_27001.png';
import iso27001Big from '../images/ISO_27001_big.png';

const FooterContainer = styled('div', {
    label: 'FooterContainer',
})({
    gap: 10,
    display: 'flex',
    maxWidth: '1200px',
    margin: '0 auto',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',

    '@media (max-width: 1030px)': {
        flexDirection: 'column',
        paddingTop: 10,
        paddingBottom: 20,
    },
});

const FooterAddress = styled('div', {
    label: 'FooterAddress',
})({
    alignItems: 'center',
    flexWrap: 'wrap',
    display: 'flex',
    gap: 20,
});

const FooterIso = styled('div', {
    label: 'FooterIso',
})({
    alignItems: 'center',
    flexWrap: 'wrap',
    display: 'flex',
    gap: 10,
});

const defaultI18n = createDefaultI18n('components.Footer', {
    contactUs: 'Contact us',
});

const noop = () => {
    /* do nothing */
    return () => {
        /* do nothing */
    };
};

const getFormattedBuildTime = () => formatDateTime(new Date(process.env.BUILD_TIME!));

const getFormattedBuildTimeServer = () => `${formatDateTimeUTC(new Date(process.env.BUILD_TIME!))} (UTC)`;

const Footer = () => {
    const i18n = useI18n(defaultI18n);

    const formattedBuildTime = useSyncExternalStore(noop, getFormattedBuildTime, getFormattedBuildTimeServer);

    return (
        <FooterContainer>
            <FooterAddress>
                <span>SIA &quot;FRACHT&quot; Skanstes 25 - Elemental blue, Riga, LV-1013, Latvia</span>
                <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://fracht.lv/en/contact-us/"
                    variant="contained"
                    color="primary"
                    component="a"
                >
                    {i18n.contactUs}
                </Button>
            </FooterAddress>
            <Typography color="text.secondary">{`v.${process.env.BUILD_VERSION} ${formattedBuildTime}`}</Typography>
            <FooterIso>
                <ISOImage code={9001} previewSource={iso9001} source={iso9001Big} />
                <ISOImage code={14001} previewSource={iso14001} source={iso14001Big} />
                <ISOImage code={27001} previewSource={iso27001} source={iso27001Big} />
            </FooterIso>
        </FooterContainer>
    );
};

export default Footer;
