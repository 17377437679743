import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum RentStatus {
    RENT = 'RENT',
    BOOK = 'BOOK',
    NONE = 'NONE',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof RentStatus>>('enums.RentStatus', {
    BOOK: 'Bookmarked',
    NONE: 'None',
    RENT: 'Rented',
});

export const RentStatusBundle: EnumBundle<typeof RentStatus> = {
    value: RentStatus,
    defaultI18n,
};
