import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export const SYS164MarkerTypeColors: Record<SYS164MarkerType, string> = {
    LOADING: '#EF6C00',
    UNLOADING: '#2E7D32',
    UNLOADING_LOADING: '#C51162',
    VIN_TO_CHANGE_LOADING: '#EF6C00',
    VIN_TO_CHANGE_UNLOADING: '#2E7D32',
    ROUTE_POINT: '#1976D2',
};

export enum SYS164MarkerType {
    LOADING = 'LOADING',
    UNLOADING = 'UNLOADING',
    UNLOADING_LOADING = 'UNLOADING_LOADING',
    VIN_TO_CHANGE_LOADING = 'VIN_TO_CHANGE_LOADING',
    VIN_TO_CHANGE_UNLOADING = 'VIN_TO_CHANGE_UNLOADING',
    ROUTE_POINT = 'ROUTE_POINT',
}

const markerIcons: EnumIcons<typeof SYS164MarkerType> = {
    VIN_TO_CHANGE_LOADING: {
        path: `MapIcons/TRUCK_LOCATION.svg`,
        color: SYS164MarkerTypeColors[SYS164MarkerType.VIN_TO_CHANGE_LOADING],
    },
    VIN_TO_CHANGE_UNLOADING: {
        path: `MapIcons/TRUCK_LOCATION.svg`,
        color: SYS164MarkerTypeColors[SYS164MarkerType.VIN_TO_CHANGE_UNLOADING],
    },
    LOADING: {
        path: `MapIcons/PLACE.svg`,
        color: SYS164MarkerTypeColors[SYS164MarkerType.LOADING],
    },
    UNLOADING: {
        path: `MapIcons/PLACE.svg`,
        color: SYS164MarkerTypeColors[SYS164MarkerType.UNLOADING],
    },
    UNLOADING_LOADING: {
        path: `MapIcons/MULTIPLE_PLACE.svg`,
        color: SYS164MarkerTypeColors[SYS164MarkerType.UNLOADING_LOADING],
    },
    ROUTE_POINT: {
        path: `MapIcons/PLACE.svg`,
        color: SYS164MarkerTypeColors[SYS164MarkerType.ROUTE_POINT],
    },
};

const markerTypeI18n = createDefaultI18n<EnumI18n<typeof SYS164MarkerType>>('enums.SYS164MarkerBundle', {
    VIN_TO_CHANGE_LOADING: 'VIN to change loading',
    VIN_TO_CHANGE_UNLOADING: 'VIN to change unloading',
    LOADING: 'Loading',
    UNLOADING: 'Unloading',
    UNLOADING_LOADING: 'Unloading-Loading',
    ROUTE_POINT: 'Route point',
});

export const SYS164MarkerTypeBundle: EnumBundle<typeof SYS164MarkerType> = {
    value: SYS164MarkerType,
    defaultI18n: markerTypeI18n,
    icons: markerIcons,
};
