import React from 'react';

type RefType<T> = React.Ref<T> | React.MutableRefObject<T> | undefined;

export default function mergeRefs<T>(...inputRefs: Array<RefType<T>>): RefType<T> {
    const filteredInputRefs = inputRefs.filter(Boolean);

    if (filteredInputRefs.length <= 1) {
        return filteredInputRefs[0];
    }
    return function (ref: T | null) {
        filteredInputRefs.forEach(inputRef => {
            if (typeof inputRef === 'function') {
                inputRef(ref);
            } else if (inputRef) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (inputRef as any).current = ref;
            }
        });
    };
}
