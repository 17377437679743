import React from 'react';
import { styled } from '@mui/material/styles';

import { useLanguagePath } from './I18nResourceLoader';
import { LanguageField } from './LanguageField';
import Logo from '../images/logo.svg';

const HeaderContainer = styled('div', {
    label: 'HeaderContainer',
})({
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const HeaderLogo = styled('a', {
    label: 'HeaderLogo',
})({
    display: 'flex',
    alignItems: 'center',
});

const Header = () => {
    const langPath = useLanguagePath();

    return (
        <HeaderContainer>
            <HeaderLogo href="/alcs/Login.html">
                <Logo height={40} />
            </HeaderLogo>
            <LanguageField width={130} name={langPath} />
        </HeaderContainer>
    );
};

export default Header;
