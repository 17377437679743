import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export const SYS307MarkerTypeColors: Partial<Record<SYS307MarkerType, string>> = {
    SELECTED: '#33CC00',
    UNSELECTED: '#2D79B2',
    NOT_SELECTABLE: '#808080',
};

export enum SYS307MarkerType {
    SELECTED = 'SELECTED',
    MULTI_SELECTED = 'MULTI_SELECTED',
    UNSELECTED = 'UNSELECTED',
    MULTI_UNSELECTED = 'MULTI_UNSELECTED',
    NOT_SELECTABLE = 'NOT_SELECTABLE',
    MULTI_NOT_SELECTABLE = 'MULTI_NOT_SELECTABLE',
}

const markerIcons: EnumIcons<typeof SYS307MarkerType> = {
    SELECTED: {
        path: `MapIcons/PLACE.svg`,
        color: SYS307MarkerTypeColors[SYS307MarkerType.SELECTED]!,
    },
    MULTI_SELECTED: {
        path: `MapIcons/MULTIPLE_PLACE.svg`,
        color: SYS307MarkerTypeColors[SYS307MarkerType.SELECTED]!,
    },
    UNSELECTED: {
        path: `MapIcons/PLACE.svg`,
        color: SYS307MarkerTypeColors[SYS307MarkerType.UNSELECTED]!,
    },
    MULTI_UNSELECTED: {
        path: `MapIcons/MULTIPLE_PLACE.svg`,
        color: SYS307MarkerTypeColors[SYS307MarkerType.UNSELECTED]!,
    },
    NOT_SELECTABLE: {
        path: `MapIcons/PLACE.svg`,
        color: SYS307MarkerTypeColors[SYS307MarkerType.NOT_SELECTABLE]!,
    },
    MULTI_NOT_SELECTABLE: {
        path: `MapIcons/MULTIPLE_PLACE.svg`,
        color: SYS307MarkerTypeColors[SYS307MarkerType.NOT_SELECTABLE]!,
    },
};

const markerTypeI18n = createDefaultI18n<EnumI18n<typeof SYS307MarkerType>>('enums.SYS307MarkerBundle', {
    SELECTED: '',
    MULTI_SELECTED: '',
    UNSELECTED: '',
    MULTI_UNSELECTED: '',
    NOT_SELECTABLE: '',
    MULTI_NOT_SELECTABLE: '',
});

export const SYS307MarkerTypeBundle: EnumBundle<typeof SYS307MarkerType> = {
    value: SYS307MarkerType,
    defaultI18n: markerTypeI18n,
    icons: markerIcons,
};
