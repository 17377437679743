import { createAsset } from '@alcs/query';

import { ClassifierDomains } from './ClassifierDomains';
import { EnumBundle } from './EnumTypes';
import { loadEnumFromDomain } from './helpers';

const useEnumFromDomainQuery = createAsset('loadEnumFromDomain', loadEnumFromDomain);

export const useEnumFromDomain = (
    domain: ClassifierDomains,
    modifiers?: Partial<EnumBundle<Record<string, string>>>,
): EnumBundle<Record<string, string>> => {
    const loadedBundle = useEnumFromDomainQuery(domain);

    if (!modifiers) {
        return loadedBundle;
    }

    return { ...loadedBundle, ...modifiers };
};
