import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

enum RentalOffersStatus {
    APR = 'APR',
    CAN = 'CAN',
    NEG = 'NEG',
    OFF = 'OFF',
    REJ = 'REJ',
    NEW = 'NEW',
}

const RentalOffersStatusI18n = createDefaultI18n<EnumI18n<typeof RentalOffersStatus>>('enums.RentalOffersStatus', {
    APR: 'Approved',
    CAN: 'Canceled',
    NEG: 'Negotiated',
    OFF: 'Offered',
    REJ: 'Rejected',
    NEW: 'NEW',
});

const RentalOfferStatusIcons: EnumIcons<typeof RentalOffersStatus> = {
    APR: {
        path: 'StatusIcons/APR_LIGHT_GREEN.svg',
        color: '',
    },
};

export const RentalOffersStatusLegendBundle: EnumBundle<typeof RentalOffersStatus> = {
    value: RentalOffersStatus,
    defaultI18n: RentalOffersStatusI18n,
    icons: RentalOfferStatusIcons,
};
