import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum RentTendersStatus {
    ALL = 'ALL',
    CAN = 'CAN',
    CRE = 'CRE',
    OFF = 'OFF',
    RC = 'RC',
    RO = 'RO',
    SUB = 'SUB',
}

const RentTendersStatusI18n = createDefaultI18n<EnumI18n<typeof RentTendersStatus>>('enums.RentTendersStatus', {
    ALL: 'All',
    CAN: 'Closed',
    CRE: 'Unpublished',
    OFF: 'Offered',
    RC: 'Round closed',
    RO: 'Round opened',
    SUB: 'Submited',
});

export const RentTendersStatusBundle: EnumBundle<typeof RentTendersStatus> = {
    value: RentTendersStatus,
    defaultI18n: RentTendersStatusI18n,
    icons: true,
};
