import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum InsuranceStatus {
    ALL = 'ALL',
    APPR = 'APPR',
    CAN = 'CAN',
    END = 'END',
    NEW = 'NEW',
    REJ = 'REJ',
}

const icons: EnumIcons<typeof InsuranceStatus> = {
    APPR: 'StatusIcons/APR_LIGHT_GREEN.svg',
    NEW: 'StatusIcons/NEW_BLUE.svg',
};

const InsuranceStatusI18n = createDefaultI18n<EnumI18n<typeof InsuranceStatus>>('enums.InsuranceStatus', {
    ALL: 'All',
    APPR: 'Approved',
    CAN: 'Canceled',
    END: 'Ended',
    NEW: 'New',
    REJ: 'Rejected',
});

export const InsuranceStatusBundle: EnumBundle<typeof InsuranceStatus> = {
    value: InsuranceStatus,
    defaultI18n: InsuranceStatusI18n,
    icons,
};
