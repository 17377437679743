export enum RouteSegmentType {
    ROAD = 'WAY',
    FERRY = 'FER',
    TUNNEL = 'TUN',
    BRIDGE = 'BRI',
    RAIL = 'RAI',
    LOCATION = 'LOC',
    TOLL_ROAD = 'TOL',
    EDIT_POINT = 'EPT',
}
