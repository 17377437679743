import { createDefaultI18n, ExtractI18nType } from './I18nContext';

export const datesDefaultI18n = createDefaultI18n('components.date', {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    shortWeekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    today: 'Today',
    week: 'Week',
    month: 'Month',
    date: 'Date',
});

export type DateI18n = ExtractI18nType<typeof datesDefaultI18n>;
