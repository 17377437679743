import { useCallback, useRef } from 'react';
import { AnyFunction } from 'composite-call-with-dates';

export const useRefCallback = <T extends AnyFunction>(callback?: T): T => {
    const callbackRef = useRef<T | undefined>(callback);

    callbackRef.current = callback;

    const normal = useCallback((...args: Parameters<T>): ReturnType<T> => callbackRef.current?.(...args), []);

    return normal as T;
};
