import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum LoadRequestOfferStatus {
    'CR.APR' = 'CR.APR',
    'CR.CAN' = 'CR.CAN',
    'CR.NEG' = 'CR.NEG',
    'CR.OFR' = 'CR.OFR',
    'CR.RJC' = 'CR.RJC',

    'MF.APR' = 'MF.APR',
    'MF.NEG' = 'MF.NEG',
    'MF.OFR' = 'MF.OFR',
    'MF.RJC' = 'MF.RJC',

    ORD = 'ORD',
    DLV = 'DLV',
    LOAD = 'LOAD',
    CAN = 'CAN',
}

const icons: EnumIcons<typeof LoadRequestOfferStatus> = {
    'CR.OFR': 'StatusIcons/CR.OFR_YELLOW.svg',
    'MF.OFR': 'StatusIcons/MF.OFR_YELLOW.svg',
    CAN: 'StatusIcons/CAN_RED.svg',
};

const loadRequestOfferStatusI18n = createDefaultI18n<EnumI18n<typeof LoadRequestOfferStatus>>(
    'enums.LoadRequestOfferStatus',
    {
        'CR.APR': 'Approved by supplier',
        'CR.CAN': 'Cancelled by supplier',
        'CR.NEG': 'Negotiated by supplier',
        'CR.OFR': 'Offered by supplier',
        'CR.RJC': 'Rejected by supplier',

        'MF.APR': 'Approved by customer',
        'MF.NEG': 'Negotiated by customer',
        'MF.OFR': 'Offered by customer',
        'MF.RJC': 'Rejected by customer',

        ORD: 'Ordered',
        DLV: 'Delivered',
        LOAD: 'Loaded',
        CAN: 'Cancelled',
    },
);

export const LoadRequestOfferStatusBundle: EnumBundle<typeof LoadRequestOfferStatus> = {
    value: LoadRequestOfferStatus,
    defaultI18n: loadRequestOfferStatusI18n,
    icons: icons,
};
