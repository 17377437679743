import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum FindUnloadedVinStatus {
    FREE_VIN = 'FREE_VIN',
    LOADED_VIN = 'LOADED_VIN',
}

const findUnloadedVinStatusColors: Record<FindUnloadedVinStatus, string> = {
    FREE_VIN: '#287e29',
    LOADED_VIN: '#BF0000',
};

const findUnloadedVinStatusI18n = createDefaultI18n<EnumI18n<typeof FindUnloadedVinStatus>>(
    'enums.FindUnloadedVinStatus',
    {
        FREE_VIN: 'Free',
        LOADED_VIN: 'Loaded',
    },
);

export const FindUnloadedVinStatusBundle: EnumBundle<typeof FindUnloadedVinStatus> & {
    colors: typeof findUnloadedVinStatusColors;
} = {
    defaultI18n: findUnloadedVinStatusI18n,
    value: FindUnloadedVinStatus,
    icons: false,
    colors: findUnloadedVinStatusColors,
};
