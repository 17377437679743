import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum TruckCalendarViewType {
    WORK = 'WORK',
    PRICE = 'PRICE',
    DISTANCE = 'DISTANCE',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof TruckCalendarViewType>>('enums.TruckCalendarViewType', {
    PRICE: 'Price',
    WORK: 'Work days',
    DISTANCE: 'Distance',
});

export const TruckCalendarViewTypeBundle: EnumBundle<typeof TruckCalendarViewType> = {
    defaultI18n,
    value: TruckCalendarViewType,
};
