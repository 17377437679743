import React, { CSSProperties, PropsWithChildren } from 'react';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import { Icon, IconButton, styled } from '@mui/material';

export type Severity = 'W' | 'E' | 'S' | 'I';

export type AlertProps = PropsWithChildren<{
    severity: Severity;
    className?: string;
    title?: string;
    onClose?: () => void;
    style?: CSSProperties;
}>;

const iconAlias = {
    W: 'warning',
    E: 'error',
    S: 'check_circle',
    I: 'info',
};

const defaultI18n = createDefaultI18n('components.UserMessage', {
    W: 'Warning',
    E: 'Error',
    S: 'Success',
    I: 'Info',
});

const textColors: Record<Severity, string> = {
    W: 'rgb(102, 60, 0)',
    I: 'rgb(13, 60, 97)',
    E: 'rgb(97, 26, 21)',
    S: 'rgb(30, 70, 32)',
};

export const backgroundColors: Record<Severity, string> = {
    W: 'rgb(255, 244, 229)',
    I: 'rgb(232, 244, 253)',
    E: 'rgb(253, 236, 234)',
    S: 'rgb(237, 247, 237)',
};

const iconColors: Record<Severity, string> = {
    W: '#ff9800',
    I: '#2196f3',
    E: '#f44336',
    S: '#4caf50',
};

const AlertRoot = styled('div', {
    label: 'AlertRoot',
    shouldForwardProp: propName => propName !== 'severity',
})<{ severity: Severity }>(({ severity }) => ({
    padding: '13px 16px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: 'auto 1fr',
    borderRadius: '4px',
    marginBottom: '5px',

    color: textColors[severity],
    backgroundColor: backgroundColors[severity],
    borderLeft: `3px solid ${iconColors[severity]}`,
}));

const AlertIcon = styled('div', {
    label: 'AlertIcon',
    shouldForwardProp: propName => propName !== 'severity',
})<{ severity: Severity }>(({ severity }) => ({
    marginRight: '16px',
    gridColumnStart: '1',
    gridRowStart: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: iconColors[severity],
}));

const AlertTitle = styled('div', {
    label: 'AlertTitle',
})({
    gridColumnStart: '2',
    gridRowStart: '1',
    fontWeight: 'bold',
    lineHeight: '20px',
    fontSize: '0.9rem',
    alignItems: 'center',
    display: 'flex',
});

const AlertContent = styled('div', {
    label: 'AlertContent',
    shouldForwardProp: propName => propName !== 'isUntitled',
})<{ isUntitled: boolean }>(({ isUntitled }) => ({
    width: '100%',
    gridColumnStart: '2',
    gridRowEnd: 'span 2',
    alignItems: 'flex-start',
    display: 'flex',
    ...(isUntitled && {
        alignItems: 'center',
    }),
}));

const AlertScrollBox = styled('div', {
    label: 'AlertScrollBox',
})({
    overflowY: 'auto',
    paddingRight: '7px',
    width: '100%',
    maxHeight: '100%',
});

const AlertClose = styled('div', {
    label: 'AlertClose',
})({
    gridColumnStart: '3',
    gridRowStart: '1',
    display: 'flex',
    alignItems: 'center',
});

export const Alert = ({ severity, onClose, children, title, className, style }: AlertProps) => {
    const i18n = useI18n(defaultI18n);

    return (
        <AlertRoot severity={severity} className={className} style={style}>
            <AlertIcon severity={severity}>
                <Icon color="inherit" title={i18n[severity]}>
                    {iconAlias[severity]}
                </Icon>
            </AlertIcon>
            {title && <AlertTitle>{title}</AlertTitle>}
            <AlertContent isUntitled={!title}>
                <AlertScrollBox>{children}</AlertScrollBox>
            </AlertContent>
            {onClose && (
                <AlertClose>
                    <IconButton color="inherit" onClick={onClose}>
                        <Icon>close</Icon>
                    </IconButton>
                </AlertClose>
            )}
        </AlertRoot>
    );
};
