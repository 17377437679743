import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

const defaultI18n = createDefaultI18n<EnumI18n<typeof PurchaseOrderType>>('enums.PurchaseOrderType', {
    SPOT: 'SPOT',
    DLR: 'Dealer',
    OEM: 'OEM',
    CON: 'Subcontracted',
});

export enum PurchaseOrderType {
    SPOT = 'SPOT',
    DLR = 'DLR',
    OEM = 'OEM',
    CON = 'CON',
}

export const PurchaseOrderTypeBundle: EnumBundle<typeof PurchaseOrderType> = {
    value: PurchaseOrderType,
    defaultI18n,
};
