import { ConstantPopupData, PopupData, TemporaryPopupData } from './PopupData';
import { PopupKey, PopupsManager } from './PopupsManager';

export default class PopupController<T = unknown> {
    private data: PopupData<T>;
    private readonly key: PopupKey;
    private readonly refresh: () => void;
    private readonly popupsManager: PopupsManager;

    public constructor(
        constantData: ConstantPopupData<T>,
        key: PopupKey,
        refresh: () => void,
        popupsManager: PopupsManager,
    ) {
        this.data = {
            constantData,
            tempData: {
                layer: -1,
                active: false,
                customData: {},
            },
        };
        this.key = key;
        this.refresh = refresh;
        this.popupsManager = popupsManager;
    }

    public show = (): void => {
        if (this.data.constantData.onShow) {
            this.data.constantData.onShow(this);
        }
        this.data.tempData.active = true;
        this.data.tempData.layer = this.popupsManager.layerController.pushBack(this.key);
        this.refresh();
    };

    public dismiss = (): void => {
        if (this.data.constantData.onDismiss) {
            this.data.constantData.onDismiss(this);
        }
        this.data.tempData.active = false;
        this.data.tempData.layer = -1;
        this.popupsManager.layerController.removeLayer(this.key);
        this.refresh();
    };

    public updateData = (data: Partial<TemporaryPopupData<T>>) => {
        this.data.tempData = { ...this.data.tempData, ...data };
        this.refresh();
        return this.data.tempData;
    };

    public updateCustomData = (data: Partial<T>): Partial<T> => {
        this.data.tempData.customData = { ...this.data.tempData.customData, ...data };
        this.refresh();
        return this.data.tempData.customData;
    };

    public getData = (): PopupData<T> => {
        return this.data;
    };

    public getKey = (): PopupKey => this.key;
}
