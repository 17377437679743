import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum TransportationStatus {
    ORD = 'ORD',
    LOAD = 'LOAD',
    PBL = 'PBL',
    PBU = 'PBU',
    PROBL = 'PROBL',
    TCH = 'TCH',
    ACD = 'ACD',
    OTH = 'OTH',
    CDM = 'CDM',
    CAN = 'CAN',
    DLV = 'DLV',
    INV = 'INV',
    PAI = 'PAI',
    LATE = 'LATE',
}

const transportationStatusI18n = createDefaultI18n<EnumI18n<typeof TransportationStatus>>(
    'enums.TransportationStatus',
    {
        ORD: 'Ordered',
        LOAD: 'Loaded',
        PBL: 'Problem at loading',
        PBU: 'Problem at unloading',
        PROBL: 'Problems',
        TCH: 'Technical problems',
        ACD: 'Accident',
        OTH: 'Other problems',
        CDM: 'Cargo damage',
        CAN: 'Canceled',
        DLV: 'Delivered',
        INV: 'Invoiced',
        PAI: 'Paid',
        LATE: 'Late pickup',
    },
);

export const TransportationStatusBundle: EnumBundle<typeof TransportationStatus> = {
    value: TransportationStatus,
    defaultI18n: transportationStatusI18n,
    icons: true,
};
