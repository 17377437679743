import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle } from './EnumTypes';

const defaultI18n = createDefaultI18n('enums.PaymentStatus', {
    ALL: 'All',
    NEW: 'New',
    CONF: 'Confirmed',
    PND: 'Pending',
    CAN: 'Cancelled',
    PAID: 'Paid',
    REJ: 'Rejected',
});

enum PaymentStatus {
    ALL = 'ALL',
    NEW = 'NEW',
    CONF = 'CONF',
    PND = 'PND',
    CAN = 'CAN',
    PAID = 'PAID',
    REJ = 'REJ',
}

export const PaymentStatusBundle: EnumBundle<typeof PaymentStatus> = {
    value: PaymentStatus,
    defaultI18n,
    icons: true,
};
