import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum InvoiceStatus {
    ALL = 'ALL',
    NEW = 'NEW',
    SUB = 'SUB',
    RECV = 'RECV',
    APPR = 'APPR',
    APPRN = 'APPRN',
    REJ = 'REJ',
    PAID = 'PAID',
    CAN = 'CAN',
}

const invoiceStatusImages: EnumIcons<typeof InvoiceStatus> = {
    APPR: 'StatusIcons/APR.svg',
};

const InvoiceStatusI18n = createDefaultI18n<EnumI18n<typeof InvoiceStatus>>('enums.InvoiceStatus', {
    ALL: 'All',
    NEW: 'Not submitted',
    SUB: 'Submitted',
    RECV: 'Received',
    APPR: 'Approved',
    APPRN: 'Approved with notes',
    REJ: 'Rejected',
    PAID: 'Paid',
    CAN: 'Canceled',
});

export const InvoiceStatusBundle: EnumBundle<typeof InvoiceStatus> = {
    value: InvoiceStatus,
    defaultI18n: InvoiceStatusI18n,
    icons: invoiceStatusImages,
};
