import { ServiceUtils } from '@alcs/services';

/**
 * Function that loads specified i18n module.
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 *   Warning: do not modify, export, or reuse this function. If any modifications required, please notify the author.
 */
export const loadI18n = async (moduleName: string, language?: string): Promise<Record<string, unknown>> => {
    try {
        const axiosInstance = ServiceUtils.createI18nAxiosInstance();
        const i18n = await axiosInstance.get(
            moduleName,
            language
                ? {
                      params: {
                          lang: language.toLocaleLowerCase(),
                      },
                  }
                : undefined,
        );
        return i18n.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return {};
    }
};
