import React, { useCallback } from 'react';
import { usePopupsFactory } from 'reactive-popups';
import { AlertColor } from '@mui/material';

import { SnackbarPopup } from './components/SnackbarPopup';
import { usePopupsLimitHandler } from './utils/usePopupsLimitHandler';
import { SnackbarContainerPosition, SnackbarGroup } from './SnackbarGroup';

const DEFAULT_LIMIT = 3;

export const useSnackbar = (position: SnackbarContainerPosition = 'top-left', limit: number = DEFAULT_LIMIT) => {
    const enqueueSnackbar = usePopupsFactory(SnackbarPopup, {}, SnackbarGroup[position]);
    usePopupsLimitHandler(SnackbarGroup[position], limit);
    const showSnackbar = useCallback(
        (message: React.ReactNode, severity: AlertColor, duration?: number) => {
            enqueueSnackbar({
                autoHideDuration: duration,
                message,
                severity,
            });
        },
        [enqueueSnackbar],
    );

    return showSnackbar;
};
