import { ComponentProps } from 'react';
import { keyframes, styled } from '@mui/material';

const shakeAnimation = keyframes({
    '10%, 90%': {
        transform: 'translateX(-1px)',
    },
    '20%, 80%': {
        transform: 'translateX(2px)',
    },
    '30%, 50%, 70%': {
        transform: 'translateX(-4px)',
    },
    '40%, 60%': {
        transform: 'translateX(4px)',
    },
});

export const ShakeContainer = styled('div', {
    label: 'ShakeContainer',
    shouldForwardProp: propName => propName !== 'shake',
})<{ shake: boolean | undefined }>(({ shake }) => ({
    ...(shake && {
        animation: `${shakeAnimation} 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both`,
        transform: 'translateX(0)',
        backfaceVisibility: 'hidden',
        perspective: '1000px',
    }),
}));

export type ShakeContainerProps = ComponentProps<typeof ShakeContainer>;
