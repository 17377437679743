import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum CancelReason {
    OTH = 'OTH',
    ERR = 'ERR',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof CancelReason>>('enums.CancelReason', {
    OTH: 'Other(specify in notes)',
    ERR: 'Datа error',
});

export const cancelReasonBundle: EnumBundle<typeof CancelReason> = {
    value: CancelReason,
    defaultI18n,
};
