import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum FindDealerOrdersStatus {
    DRF = 'DRF',
    ORD = 'ORD',
    LOAD = 'LOAD',
    DLV = 'DLV',
    CAN = 'CAN',
}

const icons: EnumIcons<typeof FindDealerOrdersStatus> = {
    DRF: { path: 'StatusIcons/NEW.svg', color: '' },
};

const FindDealerOrdersStatusI18n = createDefaultI18n<EnumI18n<typeof FindDealerOrdersStatus>>(
    'enums.FindDealerOrdersStatus',
    {
        DRF: 'Draft',
        ORD: 'Ordered',
        LOAD: 'Loaded',
        DLV: 'Delivered',
        CAN: 'Canceled',
    },
);

export const FindDealerOrdersStatusBundle: EnumBundle<typeof FindDealerOrdersStatus> = {
    value: FindDealerOrdersStatus,
    defaultI18n: FindDealerOrdersStatusI18n,
    icons: icons,
};
