import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum TransportationsReportStatus {
    ACD = 'ACD',
    CAN = 'CAN',
    ERR = 'PROBL',
    SCH = 'SCH',
    UNSCH = 'UNSCH',
    DLV = 'DLV',
    INV = 'INV',
    PINV = 'PINV',
    NOINV = 'NOINV',
    ORD = 'ORD',
    LOAD = 'LOAD',
    PAID = 'PAID',
    PPAI = 'PPAI',
}

const TransportationsReportStatusBundleI18n = createDefaultI18n<EnumI18n<typeof TransportationsReportStatus>>(
    'enums.TransportationsReportStatusBundle',
    {
        ACD: 'Accident',
        CAN: 'Canceled',
        ERR: 'Has problems',
        SCH: 'Assigned to truck',
        UNSCH: 'Not assigned to truck',
        DLV: 'Delivered',
        INV: 'Invoiced',
        PINV: 'Partially invoiced',
        NOINV: 'Not invoiced',
        ORD: 'Ordered',
        LOAD: 'Loaded',
        PAID: 'Paid',
        PPAI: 'Partially paid',
    },
);

export const TransportationsReportStatusBundle: EnumBundle<typeof TransportationsReportStatus> = {
    value: TransportationsReportStatus,
    defaultI18n: TransportationsReportStatusBundleI18n,
    icons: true,
};
