import { PopupKey } from './PopupsManager';

export interface KeyValuePair<K, V> {
    key: K;
    value: V;
}

export class PopupLayerController {
    private layers: KeyValuePair<PopupKey, number>[] = [];
    public static readonly minimalLayer = 30;

    /**
     * Pushes key to last layer. Returns layer id.
     */
    public pushBack(key: PopupKey): number {
        const curId: number = this.getLastLayer().value + 1;
        this.layers.push({ key, value: curId });
        return curId;
    }

    /**
     * Removes last layer. Returns last layer id.
     */
    public pop(): number {
        this.layers.pop();
        return this.getLastLayer().value;
    }

    /**
     * Removes layer by key value and returns removed.
     */
    public removeLayer(key: PopupKey): KeyValuePair<PopupKey, number> | null {
        let id = -1;
        for (let i = 0; i < this.layers.length; i++) {
            if (key === this.layers[i].key) {
                id = i;
                break;
            }
        }
        if (id === -1) {
            return null;
        }
        return this.layers.splice(id)[0];
    }

    /**
     * Returns layer by key. If key does not exist, returns -1.
     */
    public getLayer(key: PopupKey): number {
        for (let i = 0; i < this.layers.length; i++) {
            if (key === this.layers[i].key) {
                return this.layers[i].value;
            }
        }
        return -1;
    }

    public getLayerCount(): number {
        return this.layers.length;
    }

    /**
     * Returns last layer id and popup key.
     */
    public getLastLayer(): KeyValuePair<PopupKey | null, number> {
        if (this.layers.length === 0) {
            return { key: null, value: PopupLayerController.minimalLayer };
        }
        return this.layers[this.layers.length - 1];
    }

    public clear(): void {
        this.layers = [];
    }
}
