import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum RentalOffersStatus {
    'CR.APR' = 'CR.APR',
    'CR.CAN' = 'CR.CAN',
    'CR.NEG' = 'CR.NEG',
    'CR.OFR' = 'CR.OFR',
    'CR.RJC' = 'CR.RJC',

    'MF.APR' = 'MF.APR',
    'MF.NEG' = 'MF.NEG',
    'MF.OFR' = 'MF.OFR',
    'MF.RJC' = 'MF.RJC',
    'NEW' = 'NEW',
}

const RentalOffersStatusI18n = createDefaultI18n<EnumI18n<typeof RentalOffersStatus>>('enums.RentalOffersStatus', {
    'CR.APR': 'Approved by supplier',
    'CR.CAN': 'Canceled by supplier',
    'CR.NEG': 'Negotiated by supplier',
    'CR.OFR': 'Offered by supplier',
    'CR.RJC': 'Rejected by supplier',

    'MF.APR': 'Approved by customer',
    'MF.NEG': 'Negotiated by customer',
    'MF.OFR': 'Offered by customer',
    'MF.RJC': 'Rejected by customer',
    NEW: 'New',
});

export const RentalOffersStatusBundle: EnumBundle<typeof RentalOffersStatus> = {
    value: RentalOffersStatus,
    defaultI18n: RentalOffersStatusI18n,
    icons: true,
};
