import { useCallback, useState } from 'react';
import { useCloseHandler } from 'reactive-popups';

export type OutAnimationBag = {
    slided: boolean;
    collapsed: boolean;
    handleCollapse: () => void;
    unmount: () => void;

    handleClose: () => void;
};

export const useOutAnimation = (): OutAnimationBag => {
    const [slided, setSlided] = useState(true);
    const [collapsed, setCollapsed] = useState(true);

    const handleClose = useCallback(() => {
        setSlided(false);
    }, []);

    const handleCollapse = useCallback(() => {
        setCollapsed(false);
    }, []);

    const unmount = useCloseHandler(handleClose);

    return {
        slided,
        collapsed,
        handleCollapse,
        unmount,
        handleClose,
    };
};
