import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum IncidentStatus {
    NEW = 'NEW',
    ACC = 'ACC',
    RES = 'CLO',
    REJ = 'REJ',
}

const icons: EnumIcons<typeof IncidentStatus> = {
    ACC: 'StatusIcons/ACCEPT.svg',
};

const IncidentStatusI18n = createDefaultI18n<EnumI18n<typeof IncidentStatus>>('enums.IncidentStatus', {
    NEW: 'New',
    ACC: 'Accepted',
    RES: 'Resolved',
    REJ: 'Rejected',
});

export const IncidentStatusBundle: EnumBundle<typeof IncidentStatus> = {
    value: IncidentStatus,
    defaultI18n: IncidentStatusI18n,
    icons,
};
