import React from 'react';

import { SnackbarTransitionGroup } from './components/SnackbarTransitionGroup';
import { ErrorPopupGroup } from './ErrorPopupGroup';
import { SnackbarContainerPosition, SnackbarGroup } from './SnackbarGroup';

export const SnackbarGroups = () => (
    <React.Fragment>
        {Object.entries(SnackbarGroup).map(([position, Group]) => (
            <SnackbarTransitionGroup key={position} position={position as SnackbarContainerPosition}>
                {position === 'top-left' && <ErrorPopupGroup />}
                <Group />
            </SnackbarTransitionGroup>
        ))}
    </React.Fragment>
);
