import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum CompanyStatus {
    ALL = 'ALL',
    ACT = 'ACT',
    VER = 'VER',
    NA = 'NA',
    REV = 'REV',
    BLOCK = 'BLOCK',
    END = 'END',
    DEL = 'DEL',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof CompanyStatus>>('enums.CompanyStatus', {
    ALL: 'All',
    ACT: 'In system',
    VER: 'Verified',
    NA: 'Not in system',
    REV: 'In review',
    BLOCK: 'Blocked',
    END: 'Activity ended',
    DEL: 'Deleted',
});

const companyStatusIcons: EnumIcons<typeof CompanyStatus> = {
    ACT: 'StatusIcons/APR.svg',
    END: 'StatusIcons/ENDED.svg',
    DEL: 'StatusIcons/DELETED.svg',
};

export const companyStatusBundle: EnumBundle<typeof CompanyStatus> = {
    value: CompanyStatus,
    defaultI18n,
    icons: companyStatusIcons,
};
