import React, { useContext } from 'react';
import { IGwtContext } from '@alcs/gwt';

export const GwtContext = React.createContext({} as IGwtContext);

export function useGwt() {
    return useContext(GwtContext);
}

export function withGwt<T>(WrappedComponent: React.ComponentType<T>) {
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    return class extends React.Component<Omit<T, 'gwtContext'>> {
        static displayName = `withGwt(${wrappedComponentName})`;

        public render() {
            const { ...other } = this.props;
            return (
                <GwtContext.Consumer>
                    {(gwtContext: IGwtContext) => <WrappedComponent {...(other as T)} gwtContext={gwtContext} />}
                </GwtContext.Consumer>
            );
        }
    };
}
