import { useEffect, useRef } from 'react';
import { Popup, PopupGroup, usePopupsByGroup } from 'reactive-popups';

export const usePopupsLimitHandler = (group: PopupGroup, limit: number) => {
    const popups = usePopupsByGroup(group);
    const oldPopups = useRef<Popup<unknown>[]>([]);
    const firstPopupIndex = useRef(0);

    useEffect(() => {
        if (popups.length > oldPopups.current.length && popups.length > limit) {
            // Close first popup in the queue that hasn't been closed
            if (popups[firstPopupIndex.current]) {
                popups[firstPopupIndex.current].close();
                firstPopupIndex.current++;
            }
        } else if (popups.length < oldPopups.current.length) {
            // Decrement firstPopupIndex when popup has been unmounted
            firstPopupIndex.current = Math.max(0, firstPopupIndex.current - 1);
        }

        oldPopups.current = popups;
    }, [popups, limit]);
};
