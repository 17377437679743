import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

enum VinScheduleStatus {
    DRF = 'DRF',
    TRQ = 'TRQ',
    ORD = 'ORD',
    LOAD = 'LOAD',
    DLV = 'DLV',
}

const icons: EnumIcons<typeof VinScheduleStatus> = {
    DRF: { path: 'StatusIcons/NEW.svg', color: '' },
};

const VinScheduleStatusI18n = createDefaultI18n<EnumI18n<typeof VinScheduleStatus>>('enums.VinScheduleStatus', {
    DRF: 'Draft',
    TRQ: 'Requested (search for truck)',
    ORD: 'Ordered',
    LOAD: 'Loaded',
    DLV: 'Delivered',
});

export const VinScheduleStatusBundle: EnumBundle<typeof VinScheduleStatus> = {
    value: VinScheduleStatus,
    defaultI18n: VinScheduleStatusI18n,
    icons: icons,
};
