import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum ParticipationType {
    CUST = 'CUST',
    SUPP = 'SUPP',
}

const participationTypeI18n = createDefaultI18n<EnumI18n<typeof ParticipationType>>('enums.ParticipationType', {
    CUST: 'customer',
    SUPP: 'supplier',
});

export const ParticipationTypeBundle: EnumBundle<typeof ParticipationType> = {
    value: ParticipationType,
    defaultI18n: participationTypeI18n,
    icons: false,
};
