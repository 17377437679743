export enum AuditAlias {
    CRG = 'crg',
    CTR = 'ctr',
    DRV = 'drv',
    LSC = 'lsc',
    TRS = 'trs',
    VTRQ = 'vtrq',
    VMSG = 'vmsg',
    MVH = 'mvh',
    PMN = 'pmn',
    SRT = 'srt',
    OFR = 'ofr',
    INV = 'inv',
    ORD = 'ord',
    DPST = 'dpst',
    TRQ = 'trq',
    RENL = 'renl',
    RCTR = 'rctr',
    INC = 'inc',
    RDAY = 'rday',
    RTRS = 'rtrs',
    OVH = 'ovh',
    USR = 'usr',
    BAC = 'bac',
    CIN = 'cin',
    RAC = 'rac',
    LIC = 'lic',
    COM = 'com',
    CNH = 'cnh',
    CCMR = 'ccmr',
    TEXP = 'texp',
}
