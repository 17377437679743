/// <reference types="." />
import React from 'react';
import { DefaultPopupGroup } from '@alcs/popups';
import { Portal } from '@mui/material';

import PopupController from './PopupController';
import { PopupsManager } from './PopupsManager';

interface State {
    popups: PopupController<unknown>[];
    container: HTMLElement | null;
}

interface Props {}

export default class Popupper extends React.Component<Props, State> {
    private parentPopupsManager: PopupsManager;

    public constructor(props: Props) {
        super(props);
        this.state = {
            popups: [],
            container: null,
        };
    }

    private refresh = (popups: PopupController<unknown>[]): void => this.setState({ popups });

    public render(): React.ReactNode {
        const { popups, container } = this.state;
        return (
            <Portal container={container}>
                {popups.map((popup: PopupController<unknown>) => (
                    <React.Fragment key={popup.getKey()}>{popup.getData().constantData.render(popup)}</React.Fragment>
                ))}
                <DefaultPopupGroup />
            </Portal>
        );
    }

    componentDidMount() {
        this.parentPopupsManager = window.popupsManager;
        window.popupsManager = new PopupsManager(this.refresh);
        this.setState({ container: document.getElementById('reactPopovers') });
    }

    componentWillUnmount() {
        window.popupsManager = this.parentPopupsManager;
    }
}
