import React from 'react';
import { Spr_paging_ot } from '@alcs/beans';
import get from 'lodash/get';
import { transliterate } from 'transliteration';

import DefaultItem, { DefaultItemProps } from './DefaultItem';

export namespace ComboBoxUtils {
    /**
     * Funciton, which converts limit to Spr_paging_ot object
     * @param limit - limit which will be set as page_size
     */
    export const limitToPaging = (limit: number): Spr_paging_ot => {
        return {
            skip_rows: 0,
            order_clause: '',
            page_size: limit,
            cnt: 0,
        };
    };

    export const clampIndex = (index: number, length: number, newLength?: number) => {
        if (index >= length) {
            return length - 1;
        }
        if (index < 0) {
            return 0;
        }
        if (newLength && newLength > length && index !== length - 1) {
            return length - 1;
        }
        return index;
    };

    /**
     * Function to split given text by a substring
     * @param text          - full string
     * @param substring     - substring that will be matched
     * @returns Tuple of 3 substrings: part before, substring, part after. If substring is not a part of text, returns tuple with text in first position and 2 empty strings in others.
     */
    export const splitBySubstring = (
        text: string,
        substring: string,
    ): [before: string, bold: string, after: string] => {
        text ??= '';
        substring = substring ? substring : '';
        const transString = transliterate(text.toLowerCase());
        const index = transString.indexOf(substring.trim().toLowerCase());
        if (-1 === index || substring.length === 0) {
            return [text, '', ''];
        }

        const before = text.substring(0, index);
        const bold = text.substring(index, index + substring.length);
        const after = text.substring(index + substring.length);

        return [before, bold, after];
    };

    /**
     * Function for bolding substring
     * @param text      - full string
     * @param substring - substring which will be bolded
     */
    export const boldTransSubstring = (text: string, substring: string) => {
        const [before, bold, after] = splitBySubstring(text, substring);

        if (bold === '') {
            return <React.Fragment>{text}</React.Fragment>;
        }

        return (
            <React.Fragment>
                {before}
                <b>{bold}</b>
                {after}
            </React.Fragment>
        );
    };

    /**
     * Higher order function, which returns function for rendering suggestion.
     * @param path - path or function, for extracting display string
     */
    export const defaultSuggestionRenderer = <T,>(
        path: string | ((value: T) => React.ReactNode),
        props?: Partial<DefaultItemProps>,
    ) => {
        return (
            suggestion: T,
            query: string,
            selected: boolean,
            onClick: () => void,
            selectedSuggestionRef: React.MutableRefObject<HTMLDivElement | null>,
        ) => (
            <DefaultItem
                selected={selected}
                onClick={onClick}
                onMouseDown={e => e.preventDefault()}
                tabIndex={-1}
                {...props}
                {...(selected && { ref: selectedSuggestionRef })}
            >
                {boldTransSubstring(typeof path === 'string' ? get(suggestion, path) : path(suggestion), query)}
            </DefaultItem>
        );
    };

    export const localSearch = <T,>(array: T[], query: string, limit: number, sortingField: string): T[] =>
        array
            .filter(element => get(element, sortingField).toLocaleLowerCase().includes(query.toLocaleLowerCase()))
            .sort(
                (a, b) =>
                    get(a, sortingField).toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) -
                    get(b, sortingField).toLocaleLowerCase().indexOf(query.toLocaleLowerCase()),
            )
            .filter((_, index) => index < limit);
}
