/**
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 * @author Laurynas Duburas
 */
import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';

import { loadI18n } from './loadI18n';

export const I18nContext = React.createContext({});

/**
 * @property {T}                                defaultI18n     - defaultI18n of component, where it used
 * @property {?string}                          path            - path to get loadedI18n
 * @property {(i18n: T) => React.ReactNode}     children        - function to render wrapped component with i18n
 */
interface I18nLoaderProps<T> {
    defaultI18n: DefaultI18n<T>;
    children: (i18n: T) => React.ReactNode;
}

const I18N_TOKEN = Symbol();

export type DefaultI18n<T> = {
    path: string;
    values: T;
    _token: typeof I18N_TOKEN;
};

export type ExtractI18nType<T> = T extends DefaultI18n<infer V> ? V : never;

export const createDefaultI18n = <T,>(path: string, values: T): DefaultI18n<T> => ({
    path,
    values,
    _token: I18N_TOKEN,
});

async function fetch(
    path: string,
    loadedI18n: Record<string, unknown>,
    defaultI18n: Record<string, unknown>,
    setLoaded: (loaded: boolean) => void,
) {
    const props = await loadI18n(path);
    if (defaultI18n['commons'] && loadedI18n['conmons']) {
        props['commons'] = loadedI18n['conmons'];
    }
    set(loadedI18n, path, props ? props : {});
    setLoaded(true);
}

/**
 * I18nLoader loads i18n using I18nContext. Merges defaultI18n with loadedI18n
 * @component
 */

export const I18nLoader = <T,>({ defaultI18n, children }: I18nLoaderProps<T>) => {
    const loadedI18n = useContext(I18nContext);
    const alreadyLoaded = !defaultI18n.path || !!(defaultI18n.path && get(loadedI18n, defaultI18n.path.split('.')[0]));
    const [loaded, setLoaded] = useState(alreadyLoaded);

    useEffect(() => {
        if (!loaded) {
            fetch(defaultI18n.path, loadedI18n, defaultI18n as {}, setLoaded);
        }
    }, [loaded, loadedI18n, defaultI18n, setLoaded]);

    const i18n = useI18n(defaultI18n);

    return loaded ? <>{children(i18n)}</> : <CircularProgress size={16} thickness={6} variant="indeterminate" />;
};

export function useI18n<I>({ path, values: defaultI18n }: DefaultI18n<I>): I {
    const loadedI18n = useContext(I18nContext);

    return merge(defaultI18n, path ? get(loadedI18n, path) : loadedI18n);
}

/**
 * withI18n used to easily use i18n
 * @component
 * @param   WrappedComponent - component, where using i18n
 * @param   defaultI18n      - default i18n used in wrapped component
 * @param   path             - path to get loadedI18n with function getIn
 * @wraps I18nLoader
 */
export function withI18n<T, I>(WrappedComponent: React.ComponentType<T>, defaultI18n: DefaultI18n<I>) {
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    return class extends React.Component<Omit<T, 'i18n'>> {
        static displayName = `withI18n(${wrappedComponentName})`;
        public render() {
            const { ...other } = this.props;

            return (
                <I18nLoader defaultI18n={defaultI18n}>
                    {i18n => <WrappedComponent {...(other as T)} i18n={i18n} />}
                </I18nLoader>
            );
        }
    };
}
