import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

const waypointColors: Record<WaypointType, string> = {
    AVAILABLE: '#1976D2',
    LOADING: '#EF6C00',
    UNLOADING: '#2E7D32',
    UNLOADING_LOADING: '#C51162',
    CURRENT_COORD: '#9C27B0',
    FREE_LOC: '#D50000',
};

export enum WaypointType {
    AVAILABLE = 'AVAILABLE',
    LOADING = 'LOADING',
    UNLOADING = 'UNLOADING',
    UNLOADING_LOADING = 'UNLOADING_LOADING',
    FREE_LOC = 'FREE_LOC',
    CURRENT_COORD = 'CURRENT_COORD',
}

const waypointIcons: EnumIcons<typeof WaypointType> = {
    AVAILABLE: {
        path: `MapIcons/PLACE.svg`,
        color: waypointColors[WaypointType.AVAILABLE],
    },
    LOADING: {
        path: `MapIcons/PLACE.svg`,
        color: waypointColors[WaypointType.LOADING],
    },
    UNLOADING: {
        path: `MapIcons/PLACE.svg`,
        color: waypointColors[WaypointType.UNLOADING],
    },
    UNLOADING_LOADING: {
        path: `MapIcons/PLACE.svg`,
        color: waypointColors[WaypointType.UNLOADING_LOADING],
    },
    FREE_LOC: {
        path: `MapIcons/TRUCK_LOCATION.svg`,
        color: waypointColors[WaypointType.FREE_LOC],
    },
    CURRENT_COORD: {
        path: `MapIcons/TRUCK_LOCATION.svg`,
        color: waypointColors[WaypointType.CURRENT_COORD],
    },
};

const waypointI18n = createDefaultI18n<EnumI18n<typeof WaypointType>>('enums.WaypointTypeBundle', {
    AVAILABLE: 'Available',
    LOADING: 'Loading',
    UNLOADING: 'Unloading',
    UNLOADING_LOADING: 'Unloading-Loading',
    FREE_LOC: 'Free truck location',
    CURRENT_COORD: 'Current truck location',
});

export const WaypointTypeBundle: EnumBundle<typeof WaypointType> = {
    value: WaypointType,
    defaultI18n: waypointI18n,
    icons: waypointIcons,
};

export const MultipleWaypointTypeBundle: EnumBundle<typeof WaypointType> = {
    value: WaypointType,
    defaultI18n: waypointI18n,
    icons: {
        ...waypointIcons,
        AVAILABLE: {
            path: `MapIcons/MULTIPLE_PLACE.svg`,
            color: waypointColors[WaypointType.AVAILABLE],
        },
        LOADING: {
            path: `MapIcons/MULTIPLE_PLACE.svg`,
            color: waypointColors[WaypointType.LOADING],
        },
        UNLOADING: {
            path: `MapIcons/MULTIPLE_PLACE.svg`,
            color: waypointColors[WaypointType.UNLOADING],
        },
        UNLOADING_LOADING: {
            path: `MapIcons/MULTIPLE_PLACE.svg`,
            color: waypointColors[WaypointType.UNLOADING_LOADING],
        },
    },
};
