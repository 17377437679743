import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum TruckCourse {
    N = 'N',
    NE = 'NE',
    E = 'E',
    SE = 'SE',
    S = 'S',
    SW = 'SW',
    W = 'W',
    NW = 'NW',
    UNDEFINED = 'UNDEFINED',
}

const truckCourseI18n = createDefaultI18n<EnumI18n<typeof TruckCourse>>('enums.TruckCourseBundle', {
    N: 'North',
    NE: 'Northeast',
    E: 'East',
    SE: 'Southeast',
    S: 'South',
    SW: 'Southwest',
    W: 'West',
    NW: 'Northwest',
    UNDEFINED: 'Undefined',
});

const COURSE_ICON_COLOR = '#9C27B0';

const courseIcon = (value: TruckCourse) => ({ color: COURSE_ICON_COLOR, path: `MapIcons/TRUCK_COURSE_${value}.svg` });

const truckCourseIcons: EnumIcons<typeof TruckCourse> = {
    N: courseIcon(TruckCourse.N),
    NE: courseIcon(TruckCourse.NE),
    E: courseIcon(TruckCourse.E),
    SE: courseIcon(TruckCourse.SE),
    S: courseIcon(TruckCourse.S),
    SW: courseIcon(TruckCourse.SW),
    W: courseIcon(TruckCourse.W),
    NW: courseIcon(TruckCourse.NW),
    UNDEFINED: { color: COURSE_ICON_COLOR, path: 'MapIcons/TRUCK_LOCATION.svg' },
};

export const TruckCourseBundle: EnumBundle<typeof TruckCourse> = {
    value: TruckCourse,
    icons: truckCourseIcons,
    defaultI18n: truckCourseI18n,
};
