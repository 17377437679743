import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum ReviewStatus {
    NEW = 'NEW',
    APPR = 'APPR',
    REJ = 'REJ',
}

const ReviewStatusI18n = createDefaultI18n<EnumI18n<typeof ReviewStatus>>('enums.ReviewStatus', {
    NEW: 'Not reviewed',
    APPR: 'Approved',
    REJ: 'Rejected',
});

export const ReviewStatusBundle: EnumBundle<typeof ReviewStatus> = {
    value: ReviewStatus,
    defaultI18n: ReviewStatusI18n,
};
