import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';
import { createEnumValueValidator } from '../utils/createEnumValueValidator';

const defaultI18n = createDefaultI18n<EnumI18n<typeof EntityType>>('enums.EntityType', {
    OFR: 'Offer',
    TOFR: 'Truck Offer',
    TNDOFR: 'Tender Offer',
    ORD: 'Order',
    CON: 'Contract',
    TRS: 'Transportation',
    OFRR: 'Fleet rent offer',
    ORD_RENT: 'Fleet rent contract',
    USR2USR: 'User to user',
    LSC: 'Load schedule',
});

export enum EntityType {
    OFR = 'OFR',
    TOFR = 'TOFR',
    TNDOFR = 'TNDOFR',
    ORD = 'ORD',
    CON = 'CON',
    TRS = 'TRS',
    OFRR = 'OFRR',
    ORD_RENT = 'ORD_RENT',
    USR2USR = 'USR2USR',
    LSC = 'LSC',
}

export const EntityTypeBundle: EnumBundle<typeof EntityType> = {
    value: EntityType,
    defaultI18n,
};

export const validateEntityType = createEnumValueValidator(EntityType);
