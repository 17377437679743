import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum ArrivalNoteReceiver {
    CUST = 'CUST',
    CMP = 'CMP',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof ArrivalNoteReceiver>>('enums.ArrivalNoteReceiver', {
    CMP: 'Compound',
    CUST: 'Customer',
});

export const ArrivalNoteReceiverBundle: EnumBundle<typeof ArrivalNoteReceiver> = {
    value: ArrivalNoteReceiver,
    defaultI18n,
};
