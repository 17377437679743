import React, { forwardRef, useContext } from 'react';
import { Icon, IconProps, styled } from '@mui/material';
import clsx from 'clsx';

import { ErrorIcon } from '../icons/ErrorIcon';

type SelectFieldIconContextType = {
    displayError: boolean;
    onClick: () => void;
    error: string | undefined;
};

export const SelectFieldIconContext = React.createContext<SelectFieldIconContextType>({
    displayError: false,
    error: undefined,
    onClick: () => void 0,
});

export type SelectFieldIconProps = {
    className: string;
};

const StyledErrorIcon = styled(
    forwardRef(
        (
            { propsClassName, className, ...props }: IconProps & { propsClassName?: string },
            ref: React.Ref<HTMLSpanElement>,
        ) => <Icon {...props} className={clsx(propsClassName, className)} ref={ref} />,
    ),
    {
        label: 'StyledErrorIcon',
    },
)(({ theme }) => ({
    color: theme.palette.error.main,
    pointerEvents: 'all',
    cursor: 'pointer',
}));

export const SelectFieldIcon: React.FunctionComponent<SelectFieldIconProps> = React.memo(({ className }) => {
    const { displayError, error, onClick } = useContext(SelectFieldIconContext);

    return displayError ? (
        <ErrorIcon error={error}>
            <StyledErrorIcon onClick={onClick} propsClassName={className} fontSize="small">
                arrow_drop_down_circle
            </StyledErrorIcon>
        </ErrorIcon>
    ) : (
        <Icon color="inherit" className={className}>
            arrow_drop_down
        </Icon>
    );
});
