import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';
import { createEnumValueValidator } from '../utils/createEnumValueValidator';

export enum InvoiceOrder {
    DN = 0,
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CN = 0,
    INV = 1,
}

export enum InvoiceType {
    INV = 'INV',
    DN = 'DN',
    CN = 'CN',
}

const InvoiceTypeI18n = createDefaultI18n<EnumI18n<typeof InvoiceType>>('enums.InvoiceType', {
    INV: 'Invoice',
    DN: 'Debit note',
    CN: 'Credit note',
});

export const InvoiceTypeBundle: EnumBundle<typeof InvoiceType> = {
    value: InvoiceType,
    defaultI18n: InvoiceTypeI18n,
    icons: true,
};

export const validateInvoiceType = createEnumValueValidator(InvoiceType);
