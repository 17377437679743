import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum RequiredActionsType {
    REVIEWCIN = 'REVIEWCIN',
    UPDCIN = 'UPDCIN',
    AGREEMENT = 'AGREEMENT',
    REVIEWLIC = 'REVIEWLIC',
    UPDLIC = 'UPDLIC',
}

const requiredActionsTypeI18n = createDefaultI18n<EnumI18n<typeof RequiredActionsType>>('enums.RequiredActionsType', {
    REVIEWCIN: 'Review insurance',
    UPDCIN: 'Update insurance',
    AGREEMENT: 'Agreement',
    REVIEWLIC: 'Review license',
    UPDLIC: 'Update license',
});

export const RequiredActionsTypeBundle: EnumBundle<typeof RequiredActionsType> = {
    value: RequiredActionsType,
    defaultI18n: requiredActionsTypeI18n,
};
