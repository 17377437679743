import React, { useMemo } from 'react';
import { ClassifierDomains, useEnumFromDomain } from '@alcs/enums';
import { useI18n } from '@alcs/i18n';
import { styled } from '@mui/material/styles';

import { SelectField, SelectFieldProps } from './SelectField';
import FlagIcon from '../icons/FlagIcon';

export type LanguageFieldProps = Omit<SelectFieldProps<string>, 'renderItem' | 'items'>;

export type LanguageViewProps = {
    code: string;
    name: string;
};

const LanguageViewRoot = styled('div', {
    label: 'LanguageViewRoot',
})({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 5,
});

export const LanguageView = ({ code, name }: LanguageViewProps) => (
    <LanguageViewRoot>
        <FlagIcon countryCode={code === 'EN' ? 'GB' : code} />
        <div>{name}</div>
    </LanguageViewRoot>
);

export const LanguageField = (props: LanguageFieldProps) => {
    const enumBundle = useEnumFromDomain(ClassifierDomains.LANGUAGE);
    const items = useMemo(() => Object.values(enumBundle.value), [enumBundle.value]);
    const i18n = useI18n(enumBundle.defaultI18n);

    return <SelectField {...props} items={items} renderItem={code => <LanguageView code={code} name={i18n[code]} />} />;
};
