/**
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 * @author Laurynas Duburas
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Spr_doc_priv_ot, Spr_field_priv_ot, Spr_function_priv_ot } from '@alcs/beans';
import { IServiceCallback } from '@alcs/gwt';
import { Als_priv, emptyServiceCallback } from '@alcs/services';
import toPath from 'lodash/toPath';
import { Pxth, pxthToString, RootPathToken } from 'pxth';

import { useGwt } from './GwtContext';
import { joinPaths } from '../utils/TextUtils';

/**
 * @property {boolean} visible      - shows field
 * @property {boolean} isEditable   - makes field editable
 */
export interface FieldPrivileges {
    visible?: boolean;
    disabled?: boolean;
    isEditable?: boolean;
}

/**
 * @property {boolean} visible - shows some functionality
 * @property {boolean} enabled - enables some functionality
 */
export interface FunctionPrivileges {
    visible?: boolean;
    enabled?: boolean;
    disabledText?: string;
}

type FormFieldPrivilegesMap = Record<string, FieldPrivileges>;
type FormFuntionPrivilegesMap = Record<string, FunctionPrivileges>;

/**
 * @property {{ [key: string]: FieldPrivileges }}       fields      - object to store field privileges
 * @property {{ [key: string]: FunctionPrivileges }}    functions   - object to store function privileges
 * @property {boolean}                                  isReadonly  - makes all form not editable
 */
export interface FormPrivileges {
    fields: FormFieldPrivilegesMap;
    functions: FormFuntionPrivilegesMap;
    isReadonly: boolean;
}

type CustomPrivilegeBuilder = (
    parentPrivileges: ObjectPrivileges<unknown> & FieldPrivileges,
    path: string,
) => FieldPrivileges;

type PrimitivesToPrivileges<T> =
    Extract<T, string | number | Date | boolean | null> extends never
        ? (FieldPrivileges & ObjectPrivileges<T>) | CustomPrivilegeBuilder
        : FieldPrivileges | CustomPrivilegeBuilder;

type ObjectPrivileges<T> = {
    [P in keyof T]?: PrimitivesToPrivileges<T[P] extends unknown[] ? T[P][0] : T[P]>;
};

export const initialPrivileges: FormPrivileges = {
    isReadonly: false,
    fields: {},
    functions: {},
};

export const FormPrivilegesContext = React.createContext(initialPrivileges);

export function withFunctionPrivileges<T extends { name?: string }>(WrappedComponent: React.ComponentType<T>) {
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    return class extends React.Component<Omit<T, 'functionPrivileges'>> {
        static displayName = `withFunctionPrivileges(${wrappedComponentName})`;
        public render() {
            const { name, ...other } = this.props;
            return (
                <FormPrivilegesContext.Consumer>
                    {(formPrivileges: FormPrivileges) => (
                        <WrappedComponent
                            {...(other as T)}
                            name={name}
                            functionPrivileges={
                                name
                                    ? getFunctionPrivileges(name!, formPrivileges)
                                    : {
                                          enabled: true,
                                          visible: true,
                                      }
                            }
                        />
                    )}
                </FormPrivilegesContext.Consumer>
            );
        }
    };
}

export function withDatalistPrivileges<T extends { name: string }>(WrappedComponent: React.ComponentType<T>) {
    const Component: React.FC<Omit<T, 'columnsPrivileges' | 'updatePrivileges'>> = props => {
        const [columnsPrivileges, setColumnsPrivileges] = useState(initialPrivileges);

        const { callback } = useGwt();

        const updatePrivileges = useCallback(async () => {
            const privileges = await loadFormPrivileges(
                props.name,
                null,
                callback ?? emptyServiceCallback,
                ({ field_privs }) => ({
                    isReadonly: false,
                    fields: convertFieldPrivs(field_privs),
                    functions: {},
                }),
            );

            setColumnsPrivileges(privileges);
        }, [callback, props.name]);

        useEffect(() => {
            updatePrivileges();
        }, [updatePrivileges]);

        return (
            <WrappedComponent
                {...(props as T)}
                columnsPrivileges={columnsPrivileges}
                updatePrivileges={updatePrivileges}
            />
        );
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    Component.displayName = `withDatalistPrivileges(${wrappedComponentName})`;

    return Component;
}

export function withFormPrivileges<T>(WrappedComponent: React.ComponentType<T>) {
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    return class extends React.Component<Omit<T, 'formPrivileges'>> {
        static displayName = `withFormPrivileges(${wrappedComponentName})`;
        public render() {
            const { ...other } = this.props;
            return (
                <FormPrivilegesContext.Consumer>
                    {(formPrivileges: FormPrivileges) => (
                        <WrappedComponent {...(other as T)} formPrivileges={formPrivileges} />
                    )}
                </FormPrivilegesContext.Consumer>
            );
        }
    };
}

const loadDocPrivileges = async (
    docType: string,
    id: number | null,
    callback: IServiceCallback,
): Promise<Spr_doc_priv_ot> => {
    const docPrivs: Spr_doc_priv_ot = await new Als_priv(callback).find_doc_priv(docType, id);
    return docPrivs;
};

const loadFormPrivileges = async (
    docType: string,
    id: number | null,
    callback: IServiceCallback,
    docPrivsToForm: (doc: Spr_doc_priv_ot) => FormPrivileges,
): Promise<FormPrivileges> => {
    const docPrivs = await loadDocPrivileges(docType, id, callback);
    return docPrivsToForm(docPrivs);
};

export const convertFieldPrivs = (privs: Spr_field_priv_ot[]): FormFieldPrivilegesMap =>
    privs.reduce<FormFieldPrivilegesMap>((previousValue: FormFieldPrivilegesMap, currentValue: Spr_field_priv_ot) => {
        previousValue[currentValue.field_code] = {
            visible: currentValue.is_visible === 'Y',
            isEditable: currentValue.is_editable === 'Y',
        };
        return previousValue;
    }, {});

const convertFunctionPrivs = (privs: Spr_function_priv_ot[]): FormFuntionPrivilegesMap => {
    return privs.reduce<FormFuntionPrivilegesMap>(
        (
            previousValue: FormFuntionPrivilegesMap,
            { function_code, is_enabled, disabled_text }: Spr_function_priv_ot,
        ) => {
            previousValue[function_code] = {
                visible: is_enabled === 'Y',
                enabled: is_enabled === 'Y',
                disabledText: disabled_text,
            };
            return previousValue;
        },
        {},
    );
};

const toNormalizedFieldName = <T,>(fieldPath: Pxth<T>) => {
    const fieldName = pxthToString(fieldPath);
    if (fieldName === RootPathToken) {
        return fieldName;
    }

    return toPath(fieldName)
        .filter(val => isNaN(+val))
        .join('.');
};

export const isFieldEditable = <T,>(fieldPath: Pxth<T>, { fields, isReadonly }: FormPrivileges): boolean => {
    const fieldName = toNormalizedFieldName(fieldPath);

    if (fieldName === RootPathToken) {
        return !isReadonly;
    }

    return fieldName in fields ? !!fields[fieldName].isEditable : !isReadonly;
};

export const isFieldVisible = <T,>(path: Pxth<T>, { fields }: FormPrivileges): boolean => {
    const fieldName = toNormalizedFieldName(path);

    if (fieldName === RootPathToken) {
        return true;
    }

    return fieldName in fields ? !!fields[fieldName].visible : true;
};

export const isFieldDisabled = <T,>(path: Pxth<T>, { fields }: FormPrivileges): boolean => {
    const fieldName = toNormalizedFieldName(path);

    if (fieldName === RootPathToken) {
        return false;
    }

    return fieldName in fields ? !!fields[fieldName].disabled : false;
};

export const getFunctionPrivileges = (path: string, { functions }: FormPrivileges): FunctionPrivileges => {
    return {
        enabled: functions?.[path]?.enabled ?? true,
        visible: functions?.[path]?.visible ?? true,
        disabledText: functions?.[path]?.disabledText,
    };
};

export const getFieldPrivileges = <T,>(path: Pxth<T>, privileges: FormPrivileges): FieldPrivileges => ({
    isEditable: isFieldEditable(path, privileges),
    visible: isFieldVisible(path, privileges),
    disabled: isFieldDisabled(path, privileges),
});

/**
 * FieldPrivilegesConsumer consume field privileges
 * @component
 * @param {string}                                              name        - path to get field privileges from formPrivileges
 * @param {(fieldPrivs: FieldPrivileges) => React.ReactNode}    children    - function to render component with formPrivileges
 */
export const FieldPrivilegesConsumer = <T,>({
    name,
    children,
}: {
    name: Pxth<T>;
    children: (fieldPrivs: FieldPrivileges) => React.ReactNode;
}) => (
    <FormPrivilegesContext.Consumer>
        {formPrivileges => children(getFieldPrivileges(name, formPrivileges))}
    </FormPrivilegesContext.Consumer>
);

export const useFieldPrivileges = <T,>(path: Pxth<T>): FieldPrivileges => {
    const formPrivileges = React.useContext(FormPrivilegesContext);
    return getFieldPrivileges(path, formPrivileges);
};

export const useFunctionPrivileges = (path: string): FunctionPrivileges => {
    const formPrivileges = React.useContext(FormPrivilegesContext);
    return getFunctionPrivileges(path, formPrivileges);
};

interface FieldPrivilegesMap {
    [key: string]: {
        is_accessible: boolean;
        is_visible: boolean;
        is_editable: boolean;
    };
}

interface FunctionPrivilegesMap {
    [key: string]: {
        is_enabled: boolean;
        disabled_text: string;
    };
}

export type Parser<T> = (
    fieldPrivileges: FieldPrivilegesMap,
    functionPrivileges: FunctionPrivilegesMap,
) => {
    isReadonly: boolean;
    fields: ObjectPrivileges<T>;
    functions: {
        [key: string]: FunctionPrivileges;
    };
};

/**
 * @function createPrivileges - function to create privileges using rawPrivileges from server and parser
 * @param rawPrivileges - privileges got from server
 * @param parser        - function to parse privileges
 * @returns {FormPrivileges} parsed privileges @see FormPrivileges
 */
export function createPrivileges<T extends object>(rawPrivileges: Spr_doc_priv_ot, parser: Parser<T>): FormPrivileges {
    const functionsFromDB = convertFunctionPrivs(rawPrivileges.function_privs);
    const privileges = parser(
        rawPrivileges.field_privs.reduce<FieldPrivilegesMap>(
            (previousValue: FieldPrivilegesMap, currentValue: Spr_field_priv_ot) => {
                previousValue[currentValue.field_code] = {
                    is_accessible: currentValue.is_accessible === 'Y',
                    is_editable: currentValue.is_editable === 'Y',
                    is_visible: currentValue.is_visible === 'Y',
                };
                return previousValue;
            },
            {},
        ),
        rawPrivileges.function_privs.reduce<FunctionPrivilegesMap>(
            (previousValue: FunctionPrivilegesMap, currentValue: Spr_function_priv_ot) => {
                previousValue[currentValue.function_code] = {
                    is_enabled: currentValue.is_enabled === 'Y',
                    disabled_text: currentValue.disabled_text,
                };
                return previousValue;
            },
            {},
        ),
    );

    return {
        isReadonly: privileges.isReadonly,
        fields: transformPrivilegesToMap(privileges.fields),
        functions: { ...functionsFromDB, ...privileges.functions },
    };
}

const keysOfFieldPrivileges = ['visible', 'disabled', 'isEditable'] as Array<keyof FieldPrivileges>;

export const inheritPrivileges: CustomPrivilegeBuilder = parentPrivileges => {
    return {
        isEditable: parentPrivileges.isEditable,
        visible: parentPrivileges.visible,
        disabled: parentPrivileges.disabled,
    };
};

function transformPrivilegesToMap<T extends Record<string, unknown>>(
    privileges: ObjectPrivileges<T>,
    path = '',
): { [field: string]: FieldPrivileges } {
    let output: { [field: string]: FieldPrivileges } = {};
    Object.keys(privileges).forEach((value: string) => {
        const normalPath = joinPaths(path, `${value}`);
        const privilege: FieldPrivileges | CustomPrivilegeBuilder | undefined = privileges[value];
        if (privilege !== null && privilege !== undefined) {
            if (typeof privilege === 'function') {
                output[normalPath] = privilege(privileges, normalPath);
            } else if (keysOfFieldPrivileges.includes(value as unknown as keyof FieldPrivileges)) {
                output[path] = {
                    ...output[path],
                    [value]: privilege,
                };
            } else {
                output = {
                    ...output,
                    ...transformPrivilegesToMap(privilege as ObjectPrivileges<Record<string, unknown>>, normalPath),
                };
            }
        }
    });

    return output;
}
