import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum TruckCompanyChangeReason {
    ERR = 'ERR',
    SOLD = 'SOLD',
}

export const defaultI18n = createDefaultI18n<EnumI18n<typeof TruckCompanyChangeReason>>(
    'enums.TruckCompanyChangeReason',
    {
        ERR: 'Truck wrongly assigned to company, fix error',
        SOLD: 'Truck was sold to other company',
    },
);

export const TruckCompanyChangeReasonBundle: EnumBundle<typeof TruckCompanyChangeReason> = {
    defaultI18n,
    value: TruckCompanyChangeReason,
};
